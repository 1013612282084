import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getTranslation } from '@Green-Dot-Corporation/eureka-lib-i18n-utils';
import { Button, VARIANTS } from '@Green-Dot-Corporation/eureka-ui-buttons';
import { Callout } from '@Green-Dot-Corporation/eureka-ui-callouts';
import {
    TextInput,
    FloatingLabelComposer,
} from '@Green-Dot-Corporation/eureka-ui-form-elements';
import WindowContainer from '../window-container/WindowContainer';
import GenericMessage from '../generic-message/GenericMessage';
import MessageInput from '../message-input/MessageInput';
import ModalWrapper from '../modal-wrapper/ModalWrapper';

import { leaveMessage } from '../../services/chatService';
import VARIANTS_ICON from '../generic-message/const/variantsConst';
// Constants
import {
    VARIANTS_ICON_TYPE,
    VARIANTS_ICON_NAME,
} from '../modal-wrapper/const/variantsConst';
import DEVICE_TYPE from './const/deviceType';
// Styles
import './styles/leave-message.scss';
import Img from '../img/Img';

const EmailTextInput = FloatingLabelComposer(TextInput);
const { PRIMARY, SECONDARY } = VARIANTS;
class LeaveMessage extends PureComponent {
    static defaultProps = {
        onChatWidgetClose: () => Promise.resolve(),
        msgMaxLength: 280,
        msgMinLength: 30,
        lineHeight: 27,
    };

    static propTypes = {
        contextCls: PropTypes.string,
        accountIdentifier: PropTypes.string,
        leaveAgentMessage: PropTypes.string,
        onChatWidgetClose: PropTypes.func,
        msgMaxLength: PropTypes.number,
        msgMinLength: PropTypes.number,
        lineHeight: PropTypes.number,
    };

    state = {
        shouldShowLeaveMessageWindow: true,
        shouldShowMessageSentWindow: false,
        value: '',
        isSendMsgBtnEnabled: false,
        shouldShowErrorMsg: false,
        userEmail: '',
        shouldShowConfirmModal: false,
        shouldShowCounter: false,
        count: 0,
        shouldShowEmailError: false,
        shouldShowEmailInput: false,
    };

    render() {
        const {
            shouldShowLeaveMessageWindow,
            shouldShowMessageSentWindow,
            shouldShowConfirmModal,
        } = this.state;

        const { contextCls } = this.props;

        return (
            <div className={cx(this.baseCls, contextCls)}>
                {shouldShowLeaveMessageWindow &&
                    this.renderLeaveMessageWindow()}
                {shouldShowMessageSentWindow && this.renderMessageSentWindow()}
                {shouldShowConfirmModal && this.renderConfirmModal()}
            </div>
        );
    }

    componentDidMount() {
        this.webChatLeaveMessageElement =
            document.querySelector('.eureka-tile');
        this.setShowEmailInput();
    }

    renderAirplaneIcon = () => (
        <Img
            filename="paper-airplane.svg"
            alt={getTranslation('leaveMessage.imgAlt.paperAirplan')}
        />
    );

    renderLeaveMessageWindow = () => {
        const { contextCls, leaveAgentMessage } = this.props;

        const {
            value,
            shouldShowErrorMsg,
            isSendMsgBtnEnabled,
            shouldShowEmailInput,
        } = this.state;

        const { userEmail, shouldShowEmailError } = this.state;

        return (
            <WindowContainer
                contextCls={`${this.baseCls}__leave-message`}
                shouldShowCloseBtn
                onCloseBtnClick={this.handleLeaveMsgCloseBtnClick}
            >
                <div
                    className={cx(
                        `${this.baseCls}__leave-message-content`,
                        contextCls,
                    )}
                >
                    <Callout
                        renderIcon={this.renderAirplaneIcon}
                        title={getTranslation('leaveMessage.calloutTitle')}
                        msg={leaveAgentMessage}
                    />
                    {shouldShowEmailInput && (
                        // TODO: replace the TextInput when Form field composer UI component available
                        <EmailTextInput
                            contextCls={`${this.baseCls}__email-input`}
                            placeholder={getTranslation(
                                'leaveMessage.emailInputText',
                            )}
                            value={userEmail}
                            maxLength={50}
                            onChange={this.handleUserEmailChange}
                            aria-label={getTranslation(
                                'leaveMessage.emailInputText',
                            )}
                            labelProps={{
                                labelText: getTranslation(
                                    'leaveMessage.emailInputText',
                                ),
                            }}
                            errorProps={{
                                errorText: shouldShowEmailError
                                    ? getTranslation(
                                          'leaveMessage.emailInputText.formatErrorMsg',
                                      )
                                    : '',
                            }}
                            hasError={shouldShowEmailError}
                        />
                    )}

                    <MessageInput
                        contextCls={`${this.baseCls}__message-input`}
                        placeholder={getTranslation(
                            'leaveMessage.messageInputText',
                        )}
                        aria-label={getTranslation(
                            'leaveMessage.messageInputAriaLabel',
                        )}
                        labelProps={{
                            labelText: getTranslation(
                                'leaveMessage.messageInputText',
                            ),
                        }}
                        value={value}
                        onChange={this.handleMessageChange}
                        rows={3}
                        renderAfterControl={this.renderAfter}
                    />

                    <p className={`${this.baseCls}__msg-input-hint`}>
                        {getTranslation('leaveMessage.messageInputHint')}
                    </p>

                    {shouldShowErrorMsg && (
                        <p className={`${this.baseCls}__error-msg`}>
                            {getTranslation('leaveMessage.errorMsg')}
                        </p>
                    )}

                    <Button
                        contextCls={`${this.baseCls}__send-msg-btn`}
                        text={getTranslation('leaveMessage.sendMessageBtnText')}
                        variant={PRIMARY}
                        onClick={this.handleSendMsgBtnClick}
                        aria-label={getTranslation(
                            'leaveMessage.sendMessageBtnText',
                        )}
                        isDisabled={!isSendMsgBtnEnabled}
                    />
                </div>
            </WindowContainer>
        );
    };

    renderAfter = () => {
        const { shouldShowCounter } = this.state;

        return (
            <div className={`${this.baseCls}__after-control-container`}>
                {shouldShowCounter && this.renderCounter()}
            </div>
        );
    };

    renderCounter = () => {
        const { msgMaxLength } = this.props;
        const { count } = this.state;

        return (
            <div className={`${this.baseCls}__counter-container`}>
                <span>
                    {count}/{msgMaxLength}
                </span>
            </div>
        );
    };

    renderMessageSentWindow = () => {
        const { contextCls, ...rest } = this.props;
        const headText = getTranslation('leaveMessage.messageSend.title');
        const subText = getTranslation('leaveMessage.messageSend.subText');

        return (
            <GenericMessage
                {...rest}
                contextCls={`${this.baseCls}__message-sent`}
                icon={VARIANTS_ICON.SUCCESS}
                headText={headText}
                subText={subText}
            />
        );
    };

    renderConfirmModal = () => {
        const { contextCls } = this.props;
        const modalActions = [
            {
                variant: PRIMARY,
                text: getTranslation('leaveMessage.modal.primaryBtn.text'),
                ariaLabel: getTranslation('leaveMessage.modal.primaryBtn.text'),
                onClick: this.handlePrimaryBtnClick,
            },
            {
                variant: SECONDARY,
                text: getTranslation('leaveMessage.modal.secondaryBtn.text'),
                ariaLabel: getTranslation(
                    'leaveMessage.modal.secondaryBtn.text',
                ),
                onClick: this.handleSecondaryBtnClick,
            },
        ];

        return (
            <ModalWrapper
                contextCls={cx(`${this.baseCls}__modal-wrapper`, contextCls)}
                modalActions={modalActions}
                renderTo={this.modalWapperRenderToElement}
                appElement={this.webChatLeaveMessageElement}
                iconName={VARIANTS_ICON_NAME.EurekaCircleX}
                iconType={VARIANTS_ICON_TYPE.INFO}
                title={getTranslation('leaveMessage.modal.title')}
                message={getTranslation('leaveMessage.modal.message')}
            />
        );
    };

    handlePrimaryBtnClick = () => {
        const { onChatWidgetClose } = this.props;
        this.setState({
            shouldShowLeaveMessageWindow: false,
            shouldShowConfirmModal: false,
        });

        if (onChatWidgetClose) {
            onChatWidgetClose();
        }
    };

    handleSecondaryBtnClick = () => {
        this.setState({ shouldShowConfirmModal: false });
    };

    handleLeaveMsgCloseBtnClick = () => {
        this.setState({ shouldShowConfirmModal: true });
    };

    handleSendMsgBtnClick = async () => {
        this.setState({
            isSendMsgBtnEnabled: false,
            shouldShowErrorMsg: false,
        });
        await this.submitMessage();
    };

    handleMessageChange = (event) => {
        let currentText = event.target.value;
        const characterCount = currentText?.length || 0;
        const { accountIdentifier, msgMinLength, msgMaxLength } = this.props;
        const { userEmail } = this.state;
        const hasUserInfo = accountIdentifier || userEmail;

        if (characterCount > msgMaxLength) {
            currentText = currentText.slice(0, msgMaxLength);
        }

        this.setState({
            value: currentText,
            isSendMsgBtnEnabled:
                hasUserInfo && currentText.length >= msgMinLength,
            shouldShowCounter: currentText.length > 0,
            count: currentText.length,
        });
    };

    handleUserEmailChange = (event) => {
        const { msgMinLength } = this.props;
        const userEmailText = event.target.value.trim();
        const hasEmail = userEmailText.length > 0;
        const { value } = this.state;
        this.setState({
            userEmail: userEmailText,
            isSendMsgBtnEnabled:
                hasEmail && value && value.length >= msgMinLength,
            shouldShowEmailError: false,
        });
    };

    setShowEmailInput = () => {
        const { accountIdentifier } = this.props;
        this.setState({ shouldShowEmailInput: !accountIdentifier });
    };

    checkEmail = (email) => {
        const emailRegex =
            /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@[a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]+)*\.([a-zA-Z]{2,4})$/;

        if (!emailRegex.test(email)) {
            this.setState({
                isSendMsgBtnEnabled: false,
                shouldShowEmailError: true,
            });

            return false;
        }

        return true;
    };

    submitMessage = async () => {
        const newState = {
            isSendMsgBtnEnabled: false,
            shouldShowErrorMsg: false,
        };
        const { userEmail, value, shouldShowEmailInput } = this.state;
        const { accountIdentifier } = this.props;

        if (shouldShowEmailInput && userEmail.length > 0) {
            if (!this.checkEmail(userEmail)) {
                return;
            }
        }

        try {
            const request = {
                account: accountIdentifier,
                email: userEmail,
                deviceType: DEVICE_TYPE.WEBSITE,
                message: value.trim(),
            };

            const response = await leaveMessage(request);

            if (response.isSuccess) {
                newState.shouldShowLeaveMessageWindow = false;
                newState.shouldShowMessageSentWindow = true;
            } else {
                newState.shouldShowErrorMsg = true;
                newState.isSendMsgBtnEnabled = true;
            }
        } catch (error) {
            newState.shouldShowErrorMsg = true;
            newState.isSendMsgBtnEnabled = true;
        } finally {
            this.setState(newState);
        }
    };

    modalWapperRenderToElement = () => {
        return this.webChatLeaveMessageElement;
    };

    baseCls = 'web-chat-leave-message';
}

export default LeaveMessage;
