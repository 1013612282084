import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Callout } from '@Green-Dot-Corporation/eureka-ui-callouts';
import { Modal } from '@Green-Dot-Corporation/eureka-ui-modals';
import { Button, VARIANTS } from '@Green-Dot-Corporation/eureka-ui-buttons';
import {
    EurekaTriangleAlert,
    EurekaCircleX,
} from '@Green-Dot-Corporation/eureka-ui-icons';
// Constants
import { VARIANTS_ICON_TYPE, VARIANTS_ICON_NAME } from './const/variantsConst';

// Styles
import './styles/modal-wrapper.scss';

const { PRIMARY, SECONDARY } = VARIANTS;

class ModalWrapper extends PureComponent {
    static defaultProps = {
        modalActions: [],
        appElement: document.getElementById('root'),
        renderTo: () => document.body,
    };

    static propTypes = {
        contextCls: PropTypes.string,
        modalActions: PropTypes.arrayOf(
            PropTypes.shape({
                variant: PropTypes.string.isRequired,
                text: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.element,
                ]),
            }),
        ),
        iconName: PropTypes.string,
        iconType: PropTypes.string,
        renderTo: PropTypes.func,
        appElement: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(Element),
        ]),
        title: PropTypes.string,
        message: PropTypes.string,
    };

    render() {
        const { contextCls, appElement, renderTo, title, message } = this.props;

        return (
            <Modal
                dialogContextCls={cx(this.baseCls, contextCls)}
                overlayContextCls={cx(this.baseOverlayCls, contextCls)}
                renderModalActions={this.renderModalActions}
                hasCloseButton={false}
                isOpen
                renderTo={renderTo}
                appElement={appElement}
            >
                <Callout
                    contextCls={`${this.baseCls}__message-content`}
                    renderIcon={this.renderIcon}
                    title={title}
                    msg={message}
                />
            </Modal>
        );
    }

    componentDidMount() {
        this.container = document.querySelector('.ReactModalPortal');

        if (this.container) {
            this.container.classList.add('react-modal-portal');
        }
    }

    renderModalActions = () => {
        const primaryAction = this.getActionByType(PRIMARY);
        const secondaryAction = this.getActionByType(SECONDARY);

        return (
            <>
                {secondaryAction && this.renderAction(secondaryAction)}
                {primaryAction && this.renderAction(primaryAction)}
            </>
        );
    };

    renderAction = (action) => {
        const { onClick, text, variant, ariaLabel } = action;
        const modCls = this.getActionsModCls();

        return (
            <Button
                contextCls={cx(`${this.baseCls}__btn`, modCls)}
                text={text}
                variant={variant}
                onClick={onClick}
                aria-label={ariaLabel}
            />
        );
    };

    renderIcon = () => {
        const { iconName, iconType } = this.props;
        const iconCls = `${this.baseCls}__icon`;
        let Icon;

        switch (iconName) {
            case VARIANTS_ICON_NAME.EurekaCircleX:
                Icon = EurekaCircleX;
                break;
            case VARIANTS_ICON_NAME.EurekaTriangleAlert:
                Icon = EurekaTriangleAlert;
                break;
            default:
                Icon = EurekaTriangleAlert;
                break;
        }

        const modCls = {
            [`${this.baseCls}__icon--primary`]:
                iconType === VARIANTS_ICON_TYPE.INFO,
            [`${this.baseCls}__icon--error`]:
                iconType === VARIANTS_ICON_TYPE.ERROR,
        };

        return (
            <div className={cx(`${this.baseCls}__icon-container`, modCls)}>
                <Icon contextCls={iconCls} />
            </div>
        );
    };

    getActionsModCls = () => {
        const { modalActions } = this.props;

        return {
            [`${this.baseCls}__btn--single`]: modalActions.length === 1,
        };
    };

    getActionByType = (actionType) => {
        const { modalActions } = this.props;

        return modalActions.find((action) => {
            return action.variant === actionType;
        });
    };

    baseCls = 'web-chat-modal-wrapper';

    baseOverlayCls = 'web-chat-modal-wrapper__overlay';
}

export default ModalWrapper;
