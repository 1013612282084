import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
    getTranslation,
    initTranslations,
} from '@Green-Dot-Corporation/eureka-lib-i18n-utils';
import { LoadingFlow } from '@Green-Dot-Corporation/eureka-ui-loading';
import ChooseOptionWindow from './components/choose-option-window/ChooseOptionWindow';
import GenericMessage from './components/generic-message/GenericMessage';
import Hoop from './components/hoop/Hoop';
import Loading from './components/loading/Loading';
import VARIANTS_ICON from './components/generic-message/const/variantsConst';
// Services
import * as TwilioService from './services/twilioService';
import { getChatConfig } from './services/chatService';
// Utils
import { importCSS } from './utils/CSSImport';
import {
    cleanData as cleanGatewayHttpClientData,
    setAccessToken,
    setApplicationId,
    setEnv,
    setPrefixAPIUrl,
} from './utils/gatewayHttpClient';
import { setPlatform } from './utils/urlHelper';
// Constants

import GlobalSettings from './utils/GlobalSettings';
import { BRAND, PLATFORM, ENVIRONMENT } from './const/urlConst';

// Styles
import './styles/chat-module.scss';

class ChatModule extends PureComponent {
    static propTypes = {
        contextCls: PropTypes.string,
        containerElement: PropTypes.object,
        accountIdentifier: PropTypes.string,
        accessToken: PropTypes.string,
        brand: PropTypes.string,
        applicationId: PropTypes.string,
        env: PropTypes.string,
        onChatWidgetClose: PropTypes.func,
        platform: PropTypes.string,
        loginUrl: PropTypes.string,
    };

    static defaultProps = {
        onChatWidgetClose: () => Promise.resolve(),
        platform: PLATFORM.BAAS,
        brand: BRAND.GO2BANK,
        env: ENVIRONMENT.PROD,
    };

    state = {
        isLoading: true,
        shouldShowChooseOptionWindow: false,
        hasError: false,
        shouldShowHoopWindow: false,
        isI18nReady: false,
    };

    render() {
        const { isDesignTokenApplied, isLoading, hasError, isI18nReady } =
            this.state;

        return (
            isDesignTokenApplied &&
            isI18nReady && (
                <LoadingFlow
                    contextCls={`${this.baseCls}__loading`}
                    isLoading={isLoading}
                    hasError={hasError}
                    renderLoadingState={this.renderLoading}
                    renderErrorState={this.renderErrorPage}
                >
                    {this.renderContent()}
                </LoadingFlow>
            )
        );
    }

    componentDidMount() {
        this.initI18n();
        this.initialSetup();
        this.fetchConfig();
        this.applyDesignToken();
    }

    componentWillUnmount() {
        this.destoryClient();
        cleanGatewayHttpClientData();
    }

    renderContent = () => {
        const { shouldShowChooseOptionWindow, shouldShowHoopWindow } =
            this.state;
        const { contextCls } = this.props;

        return (
            <div className={cx(this.baseCls, contextCls)}>
                {shouldShowChooseOptionWindow &&
                    this.renderChooseOptionWindow()}
                {shouldShowHoopWindow && this.renderHoopWindow()}
            </div>
        );
    };

    renderHoopWindow = () => {
        const { contextCls, ...rest } = this.props;
        const { chatConfigs } = this.state;
        const { hoop, isLeaveAgentMessageEnabled } = chatConfigs;

        return (
            <Hoop
                {...rest}
                contextCls={`${this.baseCls}__hoop`}
                hoop={hoop}
                isLeaveAgentMessageEnabled={isLeaveAgentMessageEnabled}
                {...chatConfigs}
            />
        );
    };

    renderChooseOptionWindow = () => {
        const { contextCls, ...rest } = this.props;
        const { chatConfigs } = this.state;
        const { ewt } = chatConfigs;

        return (
            <ChooseOptionWindow
                {...rest}
                contextCls={`${this.baseCls}__choose-option-window`}
                ewt={ewt}
                {...chatConfigs}
            />
        );
    };

    renderErrorPage = () => {
        const { contextCls, ...rest } = this.props;
        const windowTitle = getTranslation('chatModule.errorPage.title');
        const headText = getTranslation('chatModule.errorPage.headText');
        const subText = getTranslation('chatModule.errorPage.subText');

        return (
            <GenericMessage
                {...rest}
                contextCls={`${this.baseCls}__generic-message`}
                icon={VARIANTS_ICON.WARNING}
                windowTitle={windowTitle}
                headText={headText}
                subText={subText}
            />
        );
    };

    renderLoading = () => {
        const { ...rest } = this.props;
        const title = getTranslation('chatModule.loading.title');

        return (
            <Loading
                {...rest}
                contextCls={`${this.baseCls}__loading`}
                title={title}
                shouldShowCloseBtn
            />
        );
    };

    fetchConfig = async () => {
        const newState = { isLoading: false };

        try {
            const chatConfig = await getChatConfig();
            const { isAgentOnline, ...restConfig } = chatConfig;
            newState.chatConfigs = restConfig;

            if (isAgentOnline) {
                newState.shouldShowChooseOptionWindow = true;
            } else {
                newState.shouldShowHoopWindow = true;
            }
        } catch (e) {
            newState.hasError = true;
        } finally {
            this.setState(newState);
        }
    };

    initialSetup = () => {
        const { accessToken, applicationId, platform, env, brand } = this.props;

        if (accessToken) {
            setAccessToken(accessToken);
        }

        if (applicationId) {
            setApplicationId(applicationId);
        }

        if (brand) {
            GlobalSettings.Brand = brand;
        }

        if (platform) {
            setPlatform(platform.toUpperCase());
        }

        if (env) {
            setEnv(env.toUpperCase());
            GlobalSettings.Environment = env.toUpperCase();
            setPrefixAPIUrl();
        }
    };

    initI18n = async () => {
        await initTranslations({
            defaultTranslations: {
                'en-US': () => import('./i18n/en-us.json'),
            },
            shouldLoadStrings: false,
        });
        this.setState({ isI18nReady: true });
    };

    applyDesignToken = async () => {
        const { brand } = this.props;
        await importCSS(brand);
        this.setState({
            isDesignTokenApplied: true,
        });
    };

    destoryClient = async () => {
        await TwilioService.destoryClient();
    };

    baseCls = 'web-chat-module';
}

export default ChatModule;
