const BAAS_REQUEST_HEDAER = {
    REQUEST_ID: 'Request-ID',
    APPLICATION_ID: 'Application-ID',
};

const LEAGCY_REQUEST_HEDAER = {
    REQUEST_ID: 'RequestId',
    APPLICATION_ID: 'ApplicationId',
};

const REQUEST_HEDAER = {
    AUTHORIZATION: 'Authorization',
};

export { REQUEST_HEDAER, LEAGCY_REQUEST_HEDAER, BAAS_REQUEST_HEDAER };
