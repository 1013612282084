import { getApplicationId } from './gatewayHttpClient';
import GlobalSettings from './GlobalSettings';
import {
    BAAS_URL_CONFIG,
    LEGACY_URL_CONFIG,
    PLATFORM,
    PREFIX_URL_RESOURCES,
    ENVIRONMENT,
    PROGRAM_CODE as PROGRAM_CODE_CONST,
    BRAND,
    LEGACY_RUSH_CARD_URL_CONFIG,
} from '../const/urlConst';

export default {};
// For legacy, baas we will call different domain
let platform;

// On the legacy platform, when calling the back-end end conversion api,
// The front-end needs to provide programCode
// And different brands programCode is not the same
let programCode;

let urlConfig;

const PROGRAM_CODE = '{programCode}';
const APPLICATION_ID = '{applicationId}';

const setUrlConfig = () => {
    let config;

    switch (platform) {
        case PLATFORM.LEGACY:
            if (GlobalSettings.Brand.toLowerCase() === BRAND.RUSHCARD) {
                config = LEGACY_RUSH_CARD_URL_CONFIG;
            } else {
                config = LEGACY_URL_CONFIG;
            }
            break;
        case PLATFORM.BAAS:
            config = BAAS_URL_CONFIG;
            break;
        default:
            config = BAAS_URL_CONFIG;
            break;
    }
    urlConfig = config;
};

export const setPlatform = (value) => {
    platform = value;
    setUrlConfig();
};

export const setProgramCode = (value) => {
    programCode = value;
};

export const getPlatform = () => platform;

export const getProgramCode = () => programCode;

export const getUrlConfig = () => urlConfig;

export const getResourceUrl = () => {
    switch (GlobalSettings.Environment) {
        case ENVIRONMENT.PROD:
            return PREFIX_URL_RESOURCES.PROD;
        case ENVIRONMENT.PIE:
            return PREFIX_URL_RESOURCES.PIE;
        case ENVIRONMENT.QA:
            return PREFIX_URL_RESOURCES.QA;
        case ENVIRONMENT.DEV:
            return PREFIX_URL_RESOURCES.DEV;

        default:
            return PREFIX_URL_RESOURCES.PROD;
    }
};

export const parameterizeUrl = (url = '') => {
    let upperCaseBrand;

    if (GlobalSettings.Brand) {
        upperCaseBrand = GlobalSettings.Brand.toUpperCase();
    }
    const replace = url
        .replace(PROGRAM_CODE, PROGRAM_CODE_CONST[upperCaseBrand])
        .replace(APPLICATION_ID, getApplicationId());

    return replace;
};
