import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FloatingLabelComposer } from '@Green-Dot-Corporation/eureka-ui-form-elements';
import TextareaAutosize from './TextareaAutosize';

// Styles
import './styles/message-input.scss';

class MessageTextarea extends PureComponent {
    static defaultProps = {
        maxLength: 280,
        lineHeight: 27,
        maxRows: 3,
    };

    static propTypes = {
        maxLength: PropTypes.number,
        lineHeight: PropTypes.number,
        maxRows: PropTypes.number,
    };

    render() {
        return <TextareaAutosize contextCls={this.baseCls} {...this.props} />;
    }

    baseCls = 'web-chat-message-input';
}

const MessageInput = FloatingLabelComposer(MessageTextarea);
export default MessageInput;
