import { Client } from '@twilio/conversations';
import { TwilioClientState } from './const/variantsConst';

export default {};

let client;
let conversation;

export const getClient = () => client;

export const setClient = (value) => {
    client = value;
};

export const getConversation = () => conversation;

export const setConversation = (value) => {
    conversation = value;
};

export const createClient = (twilioToken) => {
    return new Promise((resolve, reject) => {
        client = new Client(twilioToken);
        client.on(Client.stateChanged, (state) => {
            if (state === TwilioClientState.INITIALIZED) {
                resolve(client);
            }

            if (state === TwilioClientState.FAILED) {
                reject(state);
            }
        });
    });
};

export const getConversationBySid = async (sid) => {
    if (!client) {
        throw new Error('twilio client must be initialized.');
    }
    conversation = await client.getConversationBySid(sid);

    return conversation;
};

export const refreshToken = async (token) => {
    client = await client.updateToken(token);
};

export const sendMessage = async (message) => {
    const messageIndex = await conversation
        .prepareMessage()
        .setBody(message.body)
        .setAttributes({ index: message.index })
        .build()
        .send();

    return messageIndex;
};

export const sendMediaMessage = async (message) => {
    const messageIndex = await conversation
        .prepareMessage()
        .setAttributes({ index: message.index })
        .addMedia(message.body)
        .build()
        .send();

    return messageIndex;
};

export const cleanData = () => {
    client = undefined;
    conversation = undefined;
};
