import { get, post } from '../utils/gatewayHttpClient';
import { getUrlConfig } from '../utils/urlHelper';

export default {};

export const getChatConfig = async () => {
    const { GET_CONFIG } = getUrlConfig();
    const config = await get(GET_CONFIG);
    const resp = {
        hoop: config.hoop,
        isAgentOnline: config.isAgentOnline,
        ewt: config.ewt,
        isLeaveAgentMessageEnabled: config.isLeaveAgentMessageEnabled,
        leaveAgentMessage: config.leaveAgentMessage,
        userInactiveInterval: config.userInactiveInterval,
    };

    return resp;
};

export const leaveMessage = async (leaveMessageRequest) => {
    const { LEAVE_MESSAGE } = getUrlConfig();
    const resp = await post(LEAVE_MESSAGE, leaveMessageRequest);

    return resp;
};

export const requestUserToken = async () => {
    const { REQUEST_USER_TOKEN } = getUrlConfig();
    const rawResp = await post(REQUEST_USER_TOKEN);
    const resp = {
        token: rawResp.token,
        email: rawResp.email,
    };

    return resp;
};

export const startConversation = async () => {
    const { START_CONVERSATION } = getUrlConfig();
    const startConversationResp = await post(START_CONVERSATION);
    const resp = {
        conversationSid: startConversationResp.conversationSid,
    };

    return resp;
};

export const endConversation = async (conversationSid, taskSid) => {
    const { END_CONVERSATION } = getUrlConfig();
    const endConversationResp = await post(END_CONVERSATION, {
        conversationSid,
        taskSid,
    });
    const resp = {
        conversationSid: endConversationResp.conversationSid,
    };

    return resp;
};
