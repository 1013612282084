import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { LinkButton } from '@Green-Dot-Corporation/eureka-ui-buttons';
import { getTranslation } from '@Green-Dot-Corporation/eureka-lib-i18n-utils';
import ChatTextBubbleRightTail from '../svg/ChatTextBubbleRightTail';
import ChatTextBubbleLeftTail from '../svg/ChatTextBubbleLeftTail';
// Styles
import './styles/chat-text-bubble.scss';

class ChatTextBubble extends PureComponent {
    static propTypes = {
        contextCls: PropTypes.string,
        text: PropTypes.string,
        isTextFromMe: PropTypes.bool,
        id: PropTypes.string,
        isFailed: PropTypes.bool,
        index: PropTypes.string,
        onRetry: PropTypes.func,
        onRemove: PropTypes.func,
    };

    static defaultProps = {
        isTextFromMe: true,
    };

    render() {
        const { contextCls, text, children, id, isFailed, isTextFromMe } =
            this.props;
        const modCls = this.getModCls();

        return (
            <div className={cx(this.baseCls, contextCls)}>
                <div className={`${this.baseCls}__bubble-actions-container`}>
                    <div
                        id={id}
                        className={cx(this.baseCls, modCls, contextCls)}
                    >
                        <div className={`${this.baseCls}__bubble-container`}>
                            {text ?? children}
                            {this.renderTail()}
                        </div>
                    </div>
                    {isTextFromMe && isFailed && this.renderActions()}
                </div>
            </div>
        );
    }

    renderActions = () => {
        const { index } = this.props;

        return (
            <div className={`${this.baseCls}__actions-container`}>
                <span className={`${this.baseCls}__actions-text`}>
                    {getTranslation('chatTextBubble.actionsContainerText')}
                </span>
                <LinkButton
                    contextCls={`${this.baseCls}__actions-link-retry`}
                    text={getTranslation(
                        'chatTextBubble.actionsContainer.retryLinkButtonText',
                    )}
                    data={{ index }}
                    onClick={this.handleRetryClick}
                    aria-label={getTranslation(
                        'chatTextBubble.actionsContainer.retryLinkButtonAriaLabel',
                    )}
                />
                <span className={`${this.baseCls}__actions-divider`}>
                    {getTranslation(
                        'chatTextBubble.actionsContainer.dividerText',
                    )}
                </span>
                <LinkButton
                    contextCls={`${this.baseCls}__actions-link-remove`}
                    text={getTranslation(
                        'chatTextBubble.actionsContainer.removeLinkButtonText',
                    )}
                    data={{ index }}
                    onClick={this.handleRemoveClick}
                    aria-label={getTranslation(
                        'chatTextBubble.actionsContainer.removeLinkButtonAriaLabel',
                    )}
                />
            </div>
        );
    };

    renderTail = () => {
        const { isTextFromMe } = this.props;
        const BubbleTail = isTextFromMe
            ? ChatTextBubbleRightTail
            : ChatTextBubbleLeftTail;

        return <BubbleTail contextCls={`${this.baseCls}__tail`} />;
    };

    handleRetryClick = (e) => {
        const { onRetry } = this.props;
        onRetry && onRetry(e);
    };

    handleRemoveClick = (e) => {
        const { onRemove } = this.props;
        onRemove && onRemove(e);
    };

    // Get Modifier Classes
    getModCls = () => {
        const { isTextFromMe } = this.props;

        return {
            [`${this.baseCls}--from-me`]: isTextFromMe,
            [`${this.baseCls}--from-other`]: !isTextFromMe,
            [`${this.baseCls}--tail-right`]: isTextFromMe,
            [`${this.baseCls}--tail-left`]: !isTextFromMe,
        };
    };

    // Base class which all component class names should be
    // derived from
    baseCls = 'web-chat-text-bubble';
}

export default ChatTextBubble;
