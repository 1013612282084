import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { LoadingState } from '@Green-Dot-Corporation/eureka-ui-loading';
import WindowContainer from '../window-container/WindowContainer';
import './styles/loading.scss';

class Loading extends PureComponent {
    static propTypes = {
        contextCls: PropTypes.string,
        title: PropTypes.string,
        msg: PropTypes.string,
        shouldShowCloseBtn: PropTypes.bool,
    };

    render() {
        const { contextCls, title, msg, shouldShowCloseBtn, ...rest } =
            this.props;

        return (
            <WindowContainer
                {...rest}
                contextCls={cx(this.baseCls, contextCls)}
                shouldShowCloseBtn={shouldShowCloseBtn}
            >
                <LoadingState
                    contextCls={`${this.baseCls}__loading-state`}
                    title={title}
                    msg={msg}
                />
            </WindowContainer>
        );
    }

    baseCls = 'web-chat-loading';
}

export default Loading;
