import { v4 as uuidv4 } from 'uuid';
import { getAccessToken, getApplicationId } from './gatewayHttpClient';
import { getPlatform } from './urlHelper';
import { PLATFORM } from '../const/urlConst';
import {
    BAAS_REQUEST_HEDAER,
    LEAGCY_REQUEST_HEDAER,
    REQUEST_HEDAER,
} from '../const/requestConst';

export default {};

const augmentHeaders = (options, isLegacy) => {
    const { headers, ...rest } = options;
    const defaultHeaders = {
        [isLegacy
            ? LEAGCY_REQUEST_HEDAER.REQUEST_ID
            : BAAS_REQUEST_HEDAER.REQUEST_ID]: uuidv4(),
        [isLegacy
            ? LEAGCY_REQUEST_HEDAER.APPLICATION_ID
            : BAAS_REQUEST_HEDAER.APPLICATION_ID]: getApplicationId(),
    };

    return { headers: { ...defaultHeaders, ...headers }, ...rest };
};

const augment = (options, isLegacy = false) => {
    const defaultHeaders = {};
    const { headers = {}, ...rest } = options || {};
    const accessToken = getAccessToken();

    if (accessToken) {
        defaultHeaders[REQUEST_HEDAER.AUTHORIZATION] = `Bearer ${accessToken}`;
    }

    return augmentHeaders(
        {
            ...rest,
            headers: { ...defaultHeaders, ...headers },
        },
        isLegacy,
    );
};

export const addTokenOptions = (options) => {
    return augment(options, getPlatform() === PLATFORM.LEGACY);
};
