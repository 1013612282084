import {
    endConversation as endChat,
    requestUserToken,
    startConversation,
} from './chatService';
import {
    cleanData as cleanTwilioClientData,
    createClient,
    getClient as getTwilioClient,
    getConversation as getTwilioClientConversation,
    getConversationBySid,
    refreshToken,
    sendMediaMessage as sendMediaMessageByTwilioClient,
    sendMessage as sendMessageByTwilioClient,
} from './clients/twilioClient';
import { ClientConnectionState } from './clients/const/variantsConst';

export default {};

let identity;

let twilioClient;

let conversation;

export const getIndentity = () => identity;

export const setIdentity = (value) => {
    identity = value;
};

export const fetchToken = async () => {
    const { token } = await requestUserToken();

    return token;
};

export const checkClientConnectionState = async () => {
    switch (twilioClient?.connectionState) {
        // client connection is denied because of invalid JWT access token. User must refresh token in order to proceed
        case ClientConnectionState.DENIED: {
            const token = await fetchToken();
            await refreshToken(token);
            break;
        }

        // client connection is in a permanent erroneous state. Client re-initialization is required.
        case ClientConnectionState.ERROR: {
            const token = await fetchToken();
            await createClient(token);
            break;
        }

        default:
            break;
    }
};

export const getClient = async () => {
    twilioClient = getTwilioClient();

    if (!twilioClient) {
        const token = await fetchToken();
        twilioClient = await createClient(token);
    }
    await checkClientConnectionState();

    return twilioClient;
};

export const fetchConversationSid = async () => {
    const { conversationSid } = await startConversation();

    return conversationSid;
};

export const getConversation = async () => {
    conversation = getTwilioClientConversation();

    if (!conversation) {
        await getClient();
        const sid = await fetchConversationSid();
        conversation = await getConversationBySid(sid);
        const { Identity } = await conversation.getAttributes();
        setIdentity(Identity);
    }

    return conversation;
};

export const cleanData = () => {
    setIdentity(undefined);
};

export const destoryClient = async () => {
    twilioClient = getTwilioClient();

    if (twilioClient) {
        await twilioClient.shutdown();
    }
    cleanTwilioClientData();
    cleanData();
};

export const endConversation = async () => {
    conversation = await getConversation();
    const { taskSid } = await conversation.getAttributes();

    if (taskSid) {
        await endChat(conversation.sid, taskSid);
    }
};

export const sendMessage = async (message) => {
    await getConversation();

    if (message?.body?.media) {
        return sendMediaMessageByTwilioClient(message);
    }

    return sendMessageByTwilioClient(message);
};
