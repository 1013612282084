import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { List, ResourceListItem } from '@Green-Dot-Corporation/eureka-ui-lists';
import { Callout } from '@Green-Dot-Corporation/eureka-ui-callouts';
import { getTranslation } from '@Green-Dot-Corporation/eureka-lib-i18n-utils';
import WindowContainer from '../window-container/WindowContainer';
import LeaveMessage from '../leave-message/LeaveMessage';
import ChatConnectingWindow from '../chat-connecting-window/ChatConnectingWindow';
import CHOOSE_OPTION_VARIANTS from './const/variantsConst';

// Styles
import './styles/choose-option-window.scss';
import Img from '../img/Img';

const { LONG_WAIT_THRESHOLD } = CHOOSE_OPTION_VARIANTS;
class ChooseOptionWindow extends PureComponent {
    static defaultProps = {
        longWaitThresholdMinutes: LONG_WAIT_THRESHOLD,
    };

    static propTypes = {
        contextCls: PropTypes.string,
        ewt: PropTypes.number,
        accountIdentifier: PropTypes.string,
        longWaitThresholdMinutes: PropTypes.number,
        loginUrl: PropTypes.string,
        isLeaveAgentMessageEnabled: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        const { accountIdentifier } = props;
        this.isLoggedIn = !!accountIdentifier;
    }

    state = {
        shouldShowChooseOptionWindow: true,
        shouldShowConnectingPage: false,
        shouldShowLeaveMessagePage: false,
    };

    render() {
        const { contextCls } = this.props;
        const {
            shouldShowChooseOptionWindow,
            shouldShowConnectingPage,
            shouldShowLeaveMessagePage,
        } = this.state;

        return (
            <div className={cx(this.baseCls, contextCls)}>
                {shouldShowConnectingPage && this.renderConnectingPage()}
                {shouldShowLeaveMessagePage && this.renderLeaveMessagePage()}
                {shouldShowChooseOptionWindow &&
                    this.renderChooseOptionWindow()}
            </div>
        );
    }

    renderChooseOptionWindow = () => {
        const { contextCls, ...rest } = this.props;

        return (
            <WindowContainer
                {...rest}
                contextCls={`${this.baseCls}__choose-option`}
                shouldShowCloseBtn
            >
                {this.renderCallout()}
                {this.renderOptions()}
            </WindowContainer>
        );
    };

    renderCallout = () => {
        const { contextCls } = this.props;
        const headText = getTranslation('chooseOptionWindow.title');

        return (
            <Callout
                contextCls={cx(`${this.baseCls}__header-container`, contextCls)}
                renderIcon={this.renderSvg}
                title={headText}
            />
        );
    };

    renderConnectingPage = () => {
        const { contextCls, ...rest } = this.props;

        return (
            <ChatConnectingWindow
                {...rest}
                contextCls={`${this.baseCls}__connecting`}
            />
        );
    };

    renderLeaveMessagePage = () => {
        const { contextCls, ...rest } = this.props;

        return (
            <LeaveMessage
                {...rest}
                contextCls={`${this.baseCls}__leave-message`}
            />
        );
    };

    renderSvg = () => {
        const { contextCls } = this.props;

        return (
            <Img
                filename="help.svg"
                alt={getTranslation('chooseOptionWindow.imgAlt.help')}
                contextCls={cx(
                    `${this.baseCls}__header-container-svg`,
                    contextCls,
                )}
            />
        );
    };

    renderOptions = () => {
        const { contextCls, isLeaveAgentMessageEnabled } = this.props;
        const translations = this.getOptionTranslations();
        const data = [
            {
                id: translations.supportOptionId,
                meta: this.isLoggedIn
                    ? translations.supportOptionLoggedInMeta
                    : translations.supportOptionNotLoggedInMeta,
                text: this.isLoggedIn
                    ? translations.supportOptionLoggedInText
                    : translations.supportOptionNotLoggedInText,
                handleClick: this.handleSupportOptionClick,
                renderIcon: this.renderSupportSvg,
            },
        ];

        if (isLeaveAgentMessageEnabled) {
            data.push({
                id: translations.messageOptionId,
                text: translations.messageOptionText,
                meta: translations.messageOptionMeta,
                handleClick: this.handleMessageOptionClick,
                renderIcon: this.renderMessageSvg,
            });
        }

        return (
            <List
                contextCls={cx(
                    `${this.baseCls}__content-container`,
                    contextCls,
                )}
                data={data}
                renderListItem={this.renderOptionsListItem}
            />
        );
    };

    renderOptionsListItem = (item) => {
        return (
            <ResourceListItem
                contextCls={`${this.baseCls}__${item.id}`}
                text={item.text}
                meta={item.meta}
                onClick={item.handleClick}
                renderIcon={item.renderIcon}
                isInteractive
                shouldRenderAfterIcon
            />
        );
    };

    renderSupportSvg = () => (
        <Img
            filename="support.svg"
            alt={getTranslation('chooseOptionWindwo.imgAlt.support')}
            contextCls={`${this.baseCls}__svg-container`}
        />
    );

    renderMessageSvg = () => (
        <Img
            filename="message.svg"
            alt={getTranslation('chooseOptionWindwo.imgAlt.message')}
            contextCls={`${this.baseCls}__svg-container`}
        />
    );

    handleSupportOptionClick = () => {
        if (this.isLoggedIn) {
            this.setState({
                shouldShowChooseOptionWindow: false,
                shouldShowConnectingPage: true,
                shouldShowLeaveMessagePage: false,
            });
        } else {
            const { loginUrl } = this.props;

            if (loginUrl) {
                window.location.href = loginUrl;
            }
        }
    };

    handleMessageOptionClick = () => {
        this.setState({
            shouldShowChooseOptionWindow: false,
            shouldShowConnectingPage: false,
            shouldShowLeaveMessagePage: true,
        });
    };

    getOptionTranslations = () => {
        const { ewt, longWaitThresholdMinutes } = this.props;
        const supportOptionLongEwtMeta = getTranslation(
            'chooseOptionWindow.resourceListItem.supportOptionLongEwtMeta',
        );
        const supportOptionShortEwtMeta = getTranslation(
            'chooseOptionWindow.resourceListItem.supportOptionShortEwtMeta',
        );
        const supportOptionLoggedInMeta =
            ewt > longWaitThresholdMinutes
                ? supportOptionLongEwtMeta
                : !!supportOptionShortEwtMeta &&
                  supportOptionShortEwtMeta.replace('{ewt}', ewt);
        const supportOptionNotLoggedInMeta = getTranslation(
            'chooseOptionWindow.resourceListItem.supportOptionNoEwtMeta',
        );
        const supportOptionNotLoggedInText = getTranslation(
            'chooseOptionWindow.resourceListItem.supportOptionNoEwtText',
        );
        const supportOptionLoggedInText = getTranslation(
            'chooseOptionWindow.resourceListItem.supportOptionEwtText',
        );
        const messageOptionMeta = getTranslation(
            'chooseOptionWindow.resourceListItem.messageOptionMeta',
        );
        const messageOptionText = getTranslation(
            'chooseOptionWindow.resourceListItem.messageOptionText',
        );
        const supportOptionId = getTranslation(
            'chooseOptionWindow.resourceListItem.supportOptionId',
        );
        const messageOptionId = getTranslation(
            'chooseOptionWindow.resourceListItem.messageOptionId',
        );

        return {
            supportOptionLongEwtMeta,
            supportOptionShortEwtMeta,
            supportOptionLoggedInMeta,
            supportOptionNotLoggedInMeta,
            supportOptionNotLoggedInText,
            supportOptionLoggedInText,
            messageOptionMeta,
            messageOptionText,
            supportOptionId,
            messageOptionId,
        };
    };

    baseCls = 'web-chat-choose-option-window';
}

export default ChooseOptionWindow;
