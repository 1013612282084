import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
    Button,
    FilePickerButton,
    VARIANTS,
} from '@Green-Dot-Corporation/eureka-ui-buttons';
import { EurekaArrowUp } from '@Green-Dot-Corporation/eureka-ui-icons';
import { getTranslation } from '@Green-Dot-Corporation/eureka-lib-i18n-utils';
import { Conversation, Client } from '@twilio/conversations';
import { v4 as uuidv4 } from 'uuid';
import WindowContainer from '../window-container/WindowContainer';
import ChatTextBubble from './ChatTextBubble';
import ChatTypingBubble from './ChatTypingBubble';
import ChatImageBubble from './ChatImageBubble';
import IconButtonChat from '../icon-button-chat/IconButtonChat';
import MessageInput from '../message-input/MessageInput';
import GenericMessage from '../generic-message/GenericMessage';
import ModalWrapper from '../modal-wrapper/ModalWrapper';
import {
    endConversation,
    getClient,
    getConversation,
    getIndentity,
    sendMessage,
} from '../../services/twilioService';
import VARIANTS_CONST from './const/variantsConst';
import {
    VARIANTS_ICON_TYPE,
    VARIANTS_ICON_NAME,
} from '../modal-wrapper/const/variantsConst';
import VARIANTS_ICON from '../generic-message/const/variantsConst';
// Styles
import './styles/chat-window.scss';
import Img from '../img/Img';

const { PRIMARY, SECONDARY } = VARIANTS;
const DEFAULT_PAGESIZE = 1000;
class ChatWindow extends PureComponent {
    static defaultProps = {
        msgMaxLength: 280,
        lineHeight: 27,
        onChatWidgetClose: () => Promise.resolve(),
    };

    static propTypes = {
        contextCls: PropTypes.string,
        msgMaxLength: PropTypes.number,
        lineHeight: PropTypes.number,
        userInactiveInterval: PropTypes.number,
        onChatWidgetClose: PropTypes.func,
    };

    constructor(props) {
        super(props);

        const { lineHeight } = props;

        const viewpointWidth = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0,
        );

        const screenWidth = window.screen.width || 0;

        let adjustedLineHeight;

        if (viewpointWidth && screenWidth) {
            adjustedLineHeight =
                Math.ceil(viewpointWidth / screenWidth) * lineHeight;
        }
        this.adjustedLineHeight = adjustedLineHeight || lineHeight;
    }

    state = {
        messages: [],
        messagesFromMe: [],
        shouldShowCounter: false,
        count: 0,
        isSendMessageBtnEnabled: false,
        shouldShowTyping: false,
        messageToSend: undefined,
        shouldShowChatWindow: true,
        shouldShowConfirmModal: false,
        shouldShowErrorWindow: false,
        shouldShowChatExpiredWindow: false,
        shouldShowChatEndedWindow: false,
        shouldShowMinimizedIconButton: false,
        scrollToBottom: false,
        shouldShowUploadWarningModal: false,
        uploadWarningReason: undefined,
    };

    render() {
        const { contextCls } = this.props;
        const {
            shouldShowChatWindow,
            shouldShowConfirmModal,
            shouldShowErrorWindow,
            shouldShowChatExpiredWindow,
            shouldShowChatEndedWindow,
            shouldShowUploadWarningModal,
            shouldShowMinimizedIconButton,
        } = this.state;

        return (
            <div className={cx(this.baseCls, contextCls)}>
                {shouldShowChatWindow && this.renderChatWindow()}
                {shouldShowConfirmModal && this.renderConfirmModal()}
                {shouldShowErrorWindow && this.renderErrorPage()}
                {shouldShowChatExpiredWindow && this.renderChatExpiredWindow()}
                {shouldShowChatEndedWindow && this.renderChatEndedWindow()}
                {shouldShowUploadWarningModal &&
                    this.renderUploadWarningModal()}
                {shouldShowMinimizedIconButton && this.renderIconButtonChat()}
            </div>
        );
    }

    componentDidUpdate(prevProps, prevStates) {
        const { scrollToBottom } = this.state;

        if (prevStates.scrollToBottom === false && scrollToBottom === true) {
            this.scrollToBottom();
        }
    }

    componentDidMount() {
        this.getConversationChatHistory();
        this.attachConversationEvents();
        this.webChatChatWindowElement = document.querySelector('.eureka-tile');
        this.setChatExpiredTimeout();
    }

    componentWillUnmount() {
        this.detachConversationEvents();
        this.clearChatExpiredTimeOut();
    }

    renderChatWindow() {
        const { contextCls, ...rest } = this.props;

        const { messages, shouldShowTyping, messageToSend } = this.state;

        return (
            <WindowContainer
                {...rest}
                contextCls={`${this.baseCls}__chat-window-container`}
                title={getTranslation('chatWindow.title')}
                shouldShowCloseBtn
                shouldShowMinimizeBtn
                onCloseBtnClick={this.handleCloseBtnClick}
                onMinimizeBtnClick={this.handleMinimizeBtnClick}
            >
                <div className={`${this.baseCls}__chat-text-container`}>
                    <div className={`${this.baseCls}__messages-container`}>
                        {messages.map((message) =>
                            message.type === VARIANTS_CONST.MEDIA
                                ? this.renderImageBubble(message)
                                : this.renderTextBubble(message),
                        )}
                        {shouldShowTyping && <ChatTypingBubble />}
                    </div>
                    <div className={`${this.baseCls}__send-message-container`}>
                        <MessageInput
                            contextCls={`${this.baseCls}__message-input`}
                            placeholder={getTranslation(
                                'chatWindow.messageInputPlaceholder',
                            )}
                            aria-label={getTranslation(
                                'chatWindow.messageInputAriaLabel',
                            )}
                            onResize={this.handleResize}
                            onChange={this.handleMessageChange}
                            onKeyDown={this.handleKeyDown}
                            onKeyPress={this.handleKeyPress}
                            renderAfterControl={this.renderAfter}
                            value={messageToSend}
                            lineHeight={this.adjustedLineHeight}
                        />
                    </div>
                </div>
            </WindowContainer>
        );
    }

    renderConfirmModal = () => {
        const { contextCls } = this.props;
        const modalActions = [
            {
                variant: PRIMARY,
                text: getTranslation('chatWindow.modal.primaryBtn.text'),
                ariaLabel: getTranslation('chatWindow.modal.primaryBtn.text'),
                onClick: this.handleModalPrimaryBtnClick,
            },
            {
                variant: SECONDARY,
                text: getTranslation('chatWindow.modal.secondaryBtn.text'),
                ariaLabel: getTranslation('chatWindow.modal.secondaryBtn.text'),
                onClick: this.handleModalSecondaryBtnClick,
            },
        ];

        // After minimizing, need to re-find the element, otherwise the modal will not render
        this.webChatChatWindowElement = document.querySelector('.eureka-tile');

        return (
            <ModalWrapper
                contextCls={cx(`${this.baseCls}__modal-wrapper`, contextCls)}
                modalActions={modalActions}
                renderTo={this.modalWapperRenderToElement}
                appElement={this.webChatChatWindowElement}
                iconName={VARIANTS_ICON_NAME.EurekaCircleX}
                iconType={VARIANTS_ICON_TYPE.INFO}
                title={getTranslation('chatWindow.modal.title')}
            />
        );
    };

    renderErrorPage = () => {
        const { contextCls, ...rest } = this.props;
        const windowTitle = getTranslation('chatWindow.errorPage.title');

        const headText = getTranslation('chatWindow.errorPage.headText');

        const subText = getTranslation('chatWindow.errorPage.subText');

        return (
            <GenericMessage
                {...rest}
                contextCls={`${this.baseCls}__chat-error-window`}
                icon={VARIANTS_ICON.WARNING}
                windowTitle={windowTitle}
                headText={headText}
                subText={subText}
            />
        );
    };

    renderCounter = () => {
        const { msgMaxLength } = this.props;

        return (
            <div className={`${this.baseCls}__counter-container`}>
                <span>
                    {this.state.count}/{msgMaxLength}
                </span>
            </div>
        );
    };

    renderActions = () => {
        const { isSendMessageBtnEnabled } = this.state;
        const fileTypesArray =
            VARIANTS_CONST.UPLOAD_FILE_TYPE_LIMITATION.split(' ');
        const fileTypes = fileTypesArray.join(', .');

        return (
            <div className={`${this.baseCls}__actions-container`}>
                <FilePickerButton
                    contextCls={`${this.baseCls}__attach-btn`}
                    renderIcon={this.renderAttachIcon}
                    onChange={this.handleFileUpload}
                    onClick={this.handleFileInputClick}
                    fileTypes={`.${fileTypes}`}
                />
                <Button
                    contextCls={`${this.baseCls}__send-btn`}
                    renderIcon={this.renderArrowUpIcon}
                    onClick={this.handleSendBtnClick}
                    variant={PRIMARY}
                    aria-label={getTranslation(
                        'chatWindow.actionsContainer.sendBtnAriaLabel',
                    )}
                    isDisabled={!isSendMessageBtnEnabled}
                />
            </div>
        );
    };

    renderAttachIcon = () => (
        <Img
            filename="attach.svg"
            alt={getTranslation('chatImageBubble.imgAlt.attach')}
        />
    );

    renderArrowUpIcon = () => <EurekaArrowUp />;

    renderAfter = () => {
        const { shouldShowCounter } = this.state;

        return (
            <div className={`${this.baseCls}__after-control-container`}>
                {shouldShowCounter && this.renderCounter()}
                {this.renderActions()}
            </div>
        );
    };

    renderUploadWarningModal = () => {
        const { uploadWarningReason } = this.state;
        const modalActions = [
            {
                variant: PRIMARY,
                text: getTranslation(
                    'chatWindow.uploadWarningModal.primaryBtn.text',
                ),
                ariaLabel: getTranslation(
                    'chatWindow.uploadWarningModal.primaryBtn.text',
                ),
                onClick: this.handleUploadModalPrimaryBtnClick,
            },
        ];

        // After minimizing, need to re-find the element, otherwise the modal will not render
        this.webChatChatWindowElement = document.querySelector('.eureka-tile');

        return (
            <ModalWrapper
                contextCls={`${this.baseCls}__modal-wrapper`}
                modalActions={modalActions}
                renderTo={this.modalWapperRenderToElement}
                appElement={this.webChatChatWindowElement}
                iconName={VARIANTS_ICON_NAME.EurekaTriangleAlert}
                iconType={VARIANTS_ICON_TYPE.ERROR}
                title={getTranslation(
                    `chatWindow.uploadWarningModal.title.${uploadWarningReason}`,
                )}
                message={getTranslation(
                    `chatWindow.uploadWarningModal.message.${uploadWarningReason}`,
                )}
            />
        );
    };

    renderTextBubble = (msg) => {
        const isTextFromMe = msg.author === getIndentity() || !msg.state;

        if (isTextFromMe) {
            return (
                <ChatTextBubble
                    id={msg.sid}
                    key={msg.sid || msg.index.toString()}
                    text={msg.body}
                    index={msg.index.toString()}
                    isFailed={msg.isFailed}
                    onRetry={this.handleRetryClick}
                    onRemove={this.handleRemoveClick}
                />
            );
        }

        return (
            <ChatTextBubble
                id={msg.sid}
                key={msg.sid}
                text={msg.body}
                isTextFromMe={false}
            />
        );
    };

    renderImageBubble = (msg) => {
        const { media } = msg;
        const { ...rest } = this.props;
        const isTextFromMe = msg.author === getIndentity() || !msg.state;

        if (isTextFromMe) {
            return (
                <ChatImageBubble
                    {...rest}
                    key={msg.sid || msg.index.toString()}
                    index={msg.index.toString()}
                    isFailed={msg.isFailed}
                    onRetry={this.handleRetryClick}
                    onRemove={this.handleRemoveClick}
                    media={media}
                />
            );
        }

        return (
            <ChatImageBubble
                {...rest}
                key={msg.sid}
                media={media}
                isFromOther
            />
        );
    };

    renderChatExpiredWindow = () => {
        const { contextCls, ...rest } = this.props;
        const windowTitle = getTranslation('chatWindow.title');
        const headText = getTranslation(
            'chatWindow.chatExpiredWindow.headText',
        );
        const subText = getTranslation('chatWindow.chatExpiredWindow.subText');

        return (
            <GenericMessage
                {...rest}
                contextCls={`${this.baseCls}__expired-window`}
                renderIcon={this.renderClockImage}
                windowTitle={windowTitle}
                headText={headText}
                subText={subText}
                shouldShowMinimizeBtn={false}
            />
        );
    };

    renderChatEndedWindow = () => {
        const { contextCls, ...rest } = this.props;
        const windowTitle = getTranslation('chatWindow.title');
        const headText = getTranslation('chatWindow.chatEndedWindow.headText');

        return (
            <GenericMessage
                {...rest}
                contextCls={`${this.baseCls}__end-chat-window`}
                windowTitle={windowTitle}
                headText={headText}
                shouldShowMinimizeBtn={false}
            />
        );
    };

    renderClockImage = () => {
        return (
            <Img
                filename="clock.svg"
                alt={getTranslation('chatImageBubble.imgAlt.clock')}
            />
        );
    };

    renderIconButtonChat = () => {
        return (
            <IconButtonChat
                contextCls={`${this.baseCls}__icon-button`}
                renderSvg={this.renderSvgChat}
                onClick={this.handleMinimizedIconBtnClick}
            />
        );
    };

    renderSvgChat = () => (
        <Img
            filename="chat.svg"
            alt={getTranslation('chatImageBubble.imgAlt.chat')}
        />
    );

    handleUploadModalPrimaryBtnClick = () => {
        this.setState({
            shouldShowUploadWarningModal: false,
        });
    };

    handleRetryClick = (e) => {
        const { data } = e;
        const { messages, messagesFromMe } = this.state;
        const msg = messages.find((obj) => {
            return obj.index === data.index;
        });
        const guid = uuidv4();

        const messageObj = {
            body: msg.body,
            index: guid,
            isFailed: false,
            type: msg.type,
            media: msg.media,
        };
        this.setState(
            {
                messages: [
                    ...messages.filter((obj) => {
                        return obj.index !== data.index;
                    }),
                    messageObj,
                ],
                messagesFromMe: [
                    ...messagesFromMe.filter((obj) => {
                        return obj.index !== data.index;
                    }),
                    messageObj,
                ],
            },
            () => {
                this.handleSendMessage(messageObj);
            },
        );
    };

    handleRemoveClick = (e) => {
        const { data } = e;
        const { messages, messagesFromMe } = this.state;
        this.setState({
            messages: [
                ...messages.filter((obj) => {
                    return obj.index !== data.index;
                }),
            ],
            messagesFromMe: [
                ...messagesFromMe.filter((obj) => {
                    return obj.index !== data.index;
                }),
            ],
        });
    };

    handleFileInputClick = (e) => {
        e.target.value = '';
    };

    handleFileUpload = (e) => {
        const { e: evt, files: fileList } = e;
        const filesCount = fileList?.length;

        if (!filesCount) {
            return;
        }

        // Only allow to upload a single file at a time
        const files = [...fileList];
        const file = files[0];

        // Check the type of file
        const types = file.type.split('/');

        if (
            VARIANTS_CONST.UPLOAD_FILE_TYPE_LIMITATION.indexOf(
                types[types.length - 1],
            ) === -1
        ) {
            evt.preventDefault();
            this.setState({
                uploadWarningReason:
                    VARIANTS_CONST.UPLOAD_FILE_WARNING_REASON
                        .UPLOAD_FILE_WARNING_TYPE,
                shouldShowUploadWarningModal: true,
            });

            return;
        }

        // Check the size of file
        if (
            file.size >
            VARIANTS_CONST.UPLOAD_FILE_SIZE_MB_LIMITATION * 1024 * 1024
        ) {
            evt.preventDefault();
            this.setState({
                uploadWarningReason:
                    VARIANTS_CONST.UPLOAD_FILE_WARNING_REASON
                        .UPLOAD_FILE_WARNING_SIZE,
                shouldShowUploadWarningModal: true,
            });

            return;
        }

        this.handleSingleFile(file);
    };

    handleSingleFile = (file) => {
        if (file) {
            const guid = uuidv4();
            const formData = new FormData();
            formData.append('file', file);
            const messageObj = {
                body: {
                    contentType: file.type,
                    filename: file.name,
                    media: formData,
                },
                type: VARIANTS_CONST.MEDIA,
                index: guid,
                isFailed: false,
                media: {
                    sid: guid,
                    filename: file.name,
                    getContentTemporaryUrl: () => Promise.resolve(),
                },
            };

            // When sending a file, make sure appending the current loading bubble
            // to the latest messages array in the state for display
            this.setState(
                (prevState) => {
                    const { messagesFromMe, messages } = prevState;

                    return {
                        messagesFromMe: [...messagesFromMe, messageObj],
                        messages: [...messages, messageObj],
                        scrollToBottom: true,
                    };
                },
                () => {
                    this.handleSendMessage(messageObj);
                },
            );
        }
    };

    handleSendMessage = async (messageObj) => {
        this.setChatExpiredTimeout();

        try {
            await sendMessage(messageObj);

            this.setState((prevState) => {
                return {
                    messagesFromMe: prevState.messagesFromMe.filter((obj) => {
                        return obj.index !== messageObj.index;
                    }),
                    scrollToBottom: true,
                };
            });
        } catch {
            this.setState((prevState) => {
                const { messages, messagesFromMe } = prevState;
                const objIndexFromMe = messagesFromMe.findIndex(
                    (obj) => obj.index === messageObj.index,
                );

                if (objIndexFromMe > -1) {
                    messagesFromMe[objIndexFromMe].isFailed = true;
                }

                const objIndex = messages.findIndex(
                    (obj) => obj.index === messageObj.index,
                );

                if (objIndex > -1) {
                    messages[objIndex].isFailed = true;
                }

                return {
                    messages,
                    messagesFromMe,
                    scrollToBottom: true,
                };
            });
        }
    };

    handleSendBtnClick = () => {
        const { messages, messageToSend, messagesFromMe } = this.state;

        if (messageToSend?.length > 0) {
            const guid = uuidv4();

            const messageObj = {
                body: messageToSend,
                index: guid,
                isFailed: false,
            };

            this.setState(
                {
                    messagesFromMe: [...messagesFromMe, messageObj],
                    messages: [...messages, messageObj],
                    count: 0,
                    isSendMessageBtnEnabled: false,
                    messageToSend: '',
                    scrollToBottom: true,
                },
                () => {
                    this.handleSendMessage(messageObj);
                },
            );
        }
    };

    handleResize = (e) => {
        const rows = Math.floor(
            e.target.scrollHeight / this.adjustedLineHeight,
        );

        if (rows > 1) {
            this.setState({ shouldShowCounter: true });
        } else {
            this.setState({ shouldShowCounter: false });
        }
    };

    handleMessageChange = async (e) => {
        const { msgMaxLength } = this.props;

        const currentText = e.target.value;
        const characterCount = currentText?.length || 0;
        const hasMessage = currentText?.trim().replace(/\n/g, '').length > 0;

        const newState = { isSendMessageBtnEnabled: hasMessage };

        if (characterCount <= msgMaxLength) {
            newState.count = characterCount;
            newState.messageToSend = e.target.value;
        } else {
            newState.count = msgMaxLength;
            newState.messageToSend = e.target.value.slice(0, msgMaxLength);
        }
        this.setState(newState);

        const conversation = await getConversation();
        await conversation.typing();
    };

    handleKeyDown = (e) => {
        const { isSendMessageBtnEnabled } = this.state;

        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();

            if (isSendMessageBtnEnabled) {
                this.handleSendBtnClick();
            }
        }
    };

    handleKeyPress = (e) => {
        const { count } = this.state;
        const { msgMaxLength } = this.props;

        if (count === msgMaxLength) {
            e.preventDefault();

            return false;
        }

        return true;
    };

    handleTypingStarted = () => {
        this.handleTyping(true);
    };

    handleTypingEnded = () => {
        this.handleTyping(false);
    };

    handleTyping = (isTyping) => {
        this.setState({
            shouldShowTyping: isTyping,
            scrollToBottom: true,
        });
    };

    handleCloseBtnClick = () => {
        this.setState({
            shouldShowConfirmModal: true,
        });
    };

    handleMinimizeBtnClick = () => {
        this.setState({
            shouldShowChatWindow: false,
            shouldShowMinimizedIconButton: true,
        });
    };

    handleMinimizedIconBtnClick = () => {
        this.setState({
            shouldShowMinimizedIconButton: false,
            shouldShowChatWindow: true,
            scrollToBottom: true,
        });
    };

    handleMessageAdded = (message) => {
        const { messages } = this.state;
        const objIndex = messages.findIndex(
            (obj) => obj.index === message?.attributes.index,
        );

        if (objIndex > -1) {
            // Replace the message that has been sent successfully
            messages.splice(objIndex, 1, message);
        } else {
            messages.push(message);
        }

        this.setState({
            messages: [...messages],
            scrollToBottom: true,
        });
    };

    handleModalPrimaryBtnClick = async () => {
        this.setState({
            shouldShowConfirmModal: false,
            shouldShowChatWindow: false,
        });

        try {
            await endConversation();
        } catch {
            const { onChatWidgetClose } = this.props;

            if (onChatWidgetClose) {
                onChatWidgetClose();
            }
        }
    };

    handleModalSecondaryBtnClick = () => {
        this.setState({
            shouldShowConfirmModal: false,
        });
    };

    handleChatEnded = async () => {
        this.clearChatExpiredTimeOut();

        const newState = {
            shouldShowMinimizedIconButton: false,
        };

        if (this.isEndChatFromChatExpiredTimeout) {
            newState.shouldShowChatEndedWindow = false;
        } else {
            newState.shouldShowChatWindow = false;
            newState.shouldShowChatEndedWindow = true;
        }
        this.setState(newState);
    };

    getConversationChatHistory = async () => {
        const newState = {
            messages: [],
            shouldShowErrorWindow: false,
            scrollToBottom: true,
        };

        try {
            const conversation = await getConversation();
            const message = await conversation.getMessages(DEFAULT_PAGESIZE);

            if (message && message.items) {
                newState.messages = message.items.filter(
                    (item) => item.type === VARIANTS_CONST.MEDIA || item.body,
                );
            }
        } catch (error) {
            newState.shouldShowErrorWindow = true;
        } finally {
            this.setState(newState);
        }
    };

    setChatExpiredTimeout = () => {
        const { userInactiveInterval } = this.props;
        const intervalMs = userInactiveInterval * 60 * 1000;

        this.clearChatExpiredTimeOut();

        this.chatExpiredTimer = setTimeout(async () => {
            try {
                this.isEndChatFromChatExpiredTimeout = true;
                await endConversation();
            } finally {
                this.setState({
                    shouldShowChatWindow: false,
                    shouldShowMinimizedIconButton: false,
                    shouldShowChatEndedWindow: false,
                    shouldShowChatExpiredWindow: true,
                });
            }
        }, intervalMs);
    };

    clearChatExpiredTimeOut = () => {
        if (this.chatExpiredTimer) {
            clearTimeout(this.chatExpiredTimer);
        }
    };

    attachConversationEvents = async () => {
        const conversation = await getConversation();
        conversation.on(Conversation.messageAdded, this.handleMessageAdded);
        conversation.on(Conversation.typingStarted, this.handleTypingStarted);
        conversation.on(Conversation.typingEnded, this.handleTypingEnded);

        const twilioClient = await getClient();
        twilioClient.on(Client.conversationLeft, this.handleChatEnded);
    };

    detachConversationEvents = async () => {
        const conversation = await getConversation();
        conversation.off(Conversation.messageAdded, this.handleMessageAdded);
        conversation.off(Conversation.typingStarted, this.handleTypingStarted);
        conversation.off(Conversation.typingEnded, this.handleTypingEnded);

        const twilioClient = await getClient();
        twilioClient.off(Client.conversationLeft, this.handleChatEnded);
    };

    scrollToBottom = () => {
        const messageContainer = document.getElementsByClassName(
            `${this.baseCls}__messages-container`,
        )[0];

        if (messageContainer) {
            messageContainer.scrollTop = messageContainer.scrollHeight;
        }
        this.setState({ scrollToBottom: false });
    };

    modalWapperRenderToElement = () => {
        return this.webChatChatWindowElement;
    };

    baseCls = 'web-chat-window';

    chatExpiredTimer;

    isEndChatFromChatExpiredTimeout;
}

export default ChatWindow;
