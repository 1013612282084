import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Callout } from '@Green-Dot-Corporation/eureka-ui-callouts';
import { getTranslation } from '@Green-Dot-Corporation/eureka-lib-i18n-utils';
import WindowContainer from '../window-container/WindowContainer';
import VARIANTS_ICON from './const/variantsConst';
import './styles/generic-message.scss';
import Img from '../img/Img';

class GenericMessage extends PureComponent {
    static propTypes = {
        contextCls: PropTypes.string,
        windowTitle: PropTypes.string,
        shouldShowMinimizeBtn: PropTypes.bool,
        icon: PropTypes.string,
        renderIcon: PropTypes.func,
        headText: PropTypes.string,
        subText: PropTypes.string,
        i18n: PropTypes.object,
    };

    static defaultProps = {
        shouldShowMinimizeBtn: false,
    };

    render() {
        const { contextCls, windowTitle, headText, subText, ...rest } =
            this.props;

        return (
            <WindowContainer
                {...rest}
                contextCls={cx(this.baseCls, contextCls)}
                title={windowTitle}
                shouldShowCloseBtn
            >
                <Callout
                    contextCls={cx(
                        `${this.baseCls}__message-content`,
                        contextCls,
                    )}
                    renderIcon={this.renderSvg}
                    title={headText}
                    msg={subText}
                />
            </WindowContainer>
        );
    }

    renderSvg = () => {
        const { icon, renderIcon } = this.props;

        if (renderIcon) {
            return renderIcon();
        }

        const imgAltWarningAlert = getTranslation(
            'genericMessage.imgAlt.warningAlert',
        );
        const imgAltSuccess = getTranslation('genericMessage.imgAlt.success');

        return icon === VARIANTS_ICON.WARNING ? (
            <Img filename="warning-alert.svg" alt={imgAltWarningAlert} />
        ) : (
            <Img filename="success.svg" alt={imgAltSuccess} />
        );
    };

    baseCls = 'web-chat-generic-message';
}

export default GenericMessage;
