import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Textarea } from '@Green-Dot-Corporation/eureka-ui-form-elements';
import { v4 as uuidv4 } from 'uuid';
import autosize from '../../utils/autosize';

const RESIZED = 'autosize:resized';
class TextareaAutosizeClass extends PureComponent {
    static defaultProps = {
        rows: 1,
    };

    static propTypes = {
        contextCls: PropTypes.string,
        rows: PropTypes.number,
        maxRows: PropTypes.number,
        onResize: PropTypes.func,
        onChange: PropTypes.func,
        onKeyPress: PropTypes.func,
        onKeyDown: PropTypes.func,
        innerRef: PropTypes.func,
        style: PropTypes.object,
        lineHeight: PropTypes.number,
    };

    state = {
        lineHeight: undefined,
    };

    render() {
        const {
            props: {
                contextCls,
                onResize,
                maxRows,
                onChange,
                onKeyDown,
                style,
                innerRef,
                children,
                lineHeight: lineHeightProp,
                ...rest
            },
            state: { lineHeight },
        } = this;

        const maxHeight =
            maxRows && lineHeight ? lineHeight * maxRows : undefined;

        return (
            <Textarea
                {...rest}
                contextCls={cx(this.baseCls, contextCls)}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                onKeyPress={this.handleKeyPress}
                style={maxHeight ? { ...style, maxHeight } : style}
                id={this.id}
            />
        );
    }

    componentDidUpdate() {
        this.textarea && autosize.update(this.textarea);
    }

    componentDidMount() {
        const { maxRows } = this.props;

        this.textarea = document.getElementById(this.id);

        if (typeof maxRows === 'number') {
            this.updateLineHeight();
            setTimeout(() => this.textarea && autosize(this.textarea));
        } else {
            this.textarea && autosize(this.textarea);
        }

        if (this.textarea) {
            this.textarea.addEventListener(RESIZED, this.handleResize);
        }
    }

    componentWillUnmount() {
        if (this.textarea) {
            this.textarea.removeEventListener(RESIZED, this.handleResize);
            autosize.destroy(this.textarea);
        }
    }

    handleChange = (e) => {
        const { onChange } = this.props;
        onChange && onChange(e);
    };

    handleKeyDown = (e) => {
        const { onKeyDown } = this.props;
        onKeyDown && onKeyDown(e);
    };

    handleKeyPress = (e) => {
        const { onKeyPress } = this.props;
        onKeyPress && onKeyPress(e);
    };

    handleResize = (e) => {
        const { onResize } = this.props;
        onResize && onResize(e);
    };

    updateLineHeight = () => {
        const { lineHeight } = this.props;

        if (this.textarea) {
            this.setState({
                lineHeight,
            });
        }
    };

    id = `textarea-autosize-${uuidv4()}`;

    baseCls = 'web-chat-textarea-autosize';
}

const TextareaAutosize = React.forwardRef((props, ref) => {
    return <TextareaAutosizeClass {...props} innerRef={ref} />;
});

export default TextareaAutosize;
