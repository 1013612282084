import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { LoadingDots } from '@Green-Dot-Corporation/eureka-ui-loading';
import ChatTextBubbleLeftTail from '../svg/ChatTextBubbleLeftTail';
// Styles
import './styles/chat-typing-bubble.scss';

class ChatTypingBubble extends PureComponent {
    static propTypes = {
        contextCls: PropTypes.string,
    };

    render() {
        const { contextCls } = this.props;

        return (
            <div className={cx(this.baseCls, contextCls)}>
                <div className={`${this.baseCls}__bubble-container`}>
                    <LoadingDots />
                    <ChatTextBubbleLeftTail
                        contextCls={`${this.baseCls}__tail`}
                    />
                </div>
            </div>
        );
    }

    baseCls = 'web-chat-typing-bubble';
}

export default ChatTypingBubble;
