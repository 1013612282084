import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { VARIANTS } from '@Green-Dot-Corporation/eureka-ui-buttons';
import { getTranslation } from '@Green-Dot-Corporation/eureka-lib-i18n-utils';
import { Conversation } from '@twilio/conversations';
import { v4 as uuidv4 } from 'uuid';
import GenericMessage from '../generic-message/GenericMessage';

import {
    endConversation,
    getConversation,
    sendMessage,
} from '../../services/twilioService';
import ChatWindow from '../chat-window/ChatWindow';
import IconButtonChat from '../icon-button-chat/IconButtonChat';
import ModalWrapper from '../modal-wrapper/ModalWrapper';
import VARIANTS_ICON from '../generic-message/const/variantsConst';
import {
    VARIANTS_ICON_NAME,
    VARIANTS_ICON_TYPE,
} from '../modal-wrapper/const/variantsConst';
import Img from '../img/Img';

const { PRIMARY, SECONDARY } = VARIANTS;
class ChatConnectingWindow extends PureComponent {
    static defaultProps = {
        onChatWidgetClose: () => Promise.resolve(),
    };

    static propTypes = {
        contextCls: PropTypes.string,
        accountIdentifier: PropTypes.string,
        onChatWidgetClose: PropTypes.func,
    };

    state = {
        shouldShowConnectingPage: true,
        shouldShowErrorPage: false,
        shouldShowChatWindowPage: false,
        shouldShowIconButtonChat: false,
        shouldShowConfirmModal: false,
        isConnectingSuccess: false,
    };

    render() {
        const {
            shouldShowConnectingPage,
            shouldShowErrorPage,
            shouldShowChatWindowPage,
            shouldShowIconButtonChat,
            shouldShowConfirmModal,
        } = this.state;
        const { contextCls } = this.props;

        return (
            <div className={cx(this.baseCls, contextCls)}>
                {shouldShowConnectingPage && this.renderConnectingPage()}
                {shouldShowChatWindowPage && this.renderChatWindowPage()}
                {shouldShowErrorPage && this.renderErrorPage()}
                {shouldShowIconButtonChat && this.renderIconButtonChat()}
                {shouldShowConfirmModal && this.renderConfirmModal()}
            </div>
        );
    }

    componentDidMount() {
        this.initConversation();
        this.webChatChatWindowElement = document.querySelector('.eureka-tile');
    }

    componentWillUnmount() {
        this.detachConversationEvents();
    }

    renderConnectingPage = () => {
        const { contextCls, ...rest } = this.props;
        const headText = getTranslation(
            'chatConnectingWindow.connectingPage.title',
        );

        return (
            <GenericMessage
                {...rest}
                contextCls={`${this.baseCls}__chat-connecting-window`}
                renderIcon={this.renderWriteMessageIcon}
                headText={headText}
                shouldShowMinimizeBtn
                onMinimizeBtnClick={this.handleMinimizeBtnClick}
                onCloseBtnClick={this.handleCloseBtnClick}
            />
        );
    };

    renderChatWindowPage = () => {
        const { contextCls, ...rest } = this.props;

        return (
            <ChatWindow
                {...rest}
                contextCls={`${this.baseCls}__chat-window`}
                shouldShowMinimizeBtn
            />
        );
    };

    renderErrorPage = () => {
        const { contextCls, ...rest } = this.props;
        const windowTitle = getTranslation(
            'chatConnectingWindow.errorPage.title',
        );
        const headText = getTranslation('chatConnectingWindow.errorPage.text');
        const subText = getTranslation(
            'chatConnectingWindow.errorPage.subText',
        );

        return (
            <GenericMessage
                {...rest}
                contextCls={`${this.baseCls}__chat-error-window`}
                icon={VARIANTS_ICON.WARNING}
                windowTitle={windowTitle}
                headText={headText}
                subText={subText}
            />
        );
    };

    renderConfirmModal = () => {
        const { contextCls } = this.props;
        const modalActions = [
            {
                variant: PRIMARY,
                text: getTranslation('chatWindow.modal.primaryBtn.text'),
                ariaLabel: getTranslation('chatWindow.modal.primaryBtn.text'),
                onClick: this.handleModalPrimaryBtnClick,
            },
            {
                variant: SECONDARY,
                text: getTranslation('chatWindow.modal.secondaryBtn.text'),
                ariaLabel: getTranslation('chatWindow.modal.secondaryBtn.text'),
                onClick: this.handleModalSecondaryBtnClick,
            },
        ];

        // After minimizing, need to re-find the element, otherwise the modal will not render
        this.webChatChatWindowElement = document.querySelector('.eureka-tile');

        return (
            <ModalWrapper
                contextCls={cx(`${this.baseCls}__modal-wrapper`, contextCls)}
                modalActions={modalActions}
                renderTo={this.modalWapperRenderToElement}
                appElement={this.webChatChatWindowElement}
                iconName={VARIANTS_ICON_NAME.EurekaCircleX}
                iconType={VARIANTS_ICON_TYPE.INFO}
                title={getTranslation('chatWindow.modal.title')}
            />
        );
    };

    renderWriteMessageIcon = () => {
        const imgAltWriteAMessage = getTranslation(
            'chatConnectingWindow.imgAlt.writeAMessage',
        );

        return <Img filename="write-a-message.svg" alt={imgAltWriteAMessage} />;
    };

    renderSvgChat = () => {
        const imgAltChat = getTranslation('chatConnectingWindow.imgAlt.chat');

        return <Img filename="chat.svg" alt={imgAltChat} />;
    };

    renderIconButtonChat = () => {
        return (
            <IconButtonChat
                contextCls={`${this.baseCls}__chat-icon-button`}
                renderSvg={this.renderSvgChat}
                onClick={this.handleMaximizeBtnClick}
            />
        );
    };

    handleJoined = () => {
        const { shouldShowIconButtonChat, shouldShowConfirmModal } = this.state;
        const newState = {
            isConnectingSuccess: true,
            shouldShowConnectingPage: false,
            shouldShowErrorPage: false,
            shouldShowChatWindowPage: false,
            shouldShowIconButtonChat: false,
            shouldShowConfirmModal: false,
        };

        if (shouldShowIconButtonChat) {
            newState.shouldShowIconButtonChat = true;
        } else if (shouldShowConfirmModal) {
            newState.shouldShowConfirmModal = true;
            newState.shouldShowConnectingPage = true;
        } else {
            newState.shouldShowChatWindowPage = true;
        }

        this.setState(newState);
    };

    handleError = () => {
        this.setState({
            shouldShowConnectingPage: false,
            shouldShowErrorPage: true,
            shouldShowChatWindowPage: false,
            shouldShowIconButtonChat: false,
        });
    };

    handleMinimizeBtnClick = () => {
        this.setState({
            shouldShowConnectingPage: false,
            shouldShowErrorPage: false,
            shouldShowChatWindowPage: false,
            shouldShowIconButtonChat: true,
        });
    };

    handleMaximizeBtnClick = () => {
        const { isConnectingSuccess } = this.state;
        const newState = {
            shouldShowConnectingPage: false,
            shouldShowErrorPage: false,
            shouldShowChatWindowPage: false,
            shouldShowIconButtonChat: false,
        };

        if (isConnectingSuccess) {
            newState.shouldShowChatWindowPage = true;
        } else {
            newState.shouldShowConnectingPage = true;
        }
        this.setState(newState);
    };

    handleModalPrimaryBtnClick = async () => {
        this.setState({
            shouldShowConfirmModal: false,
            shouldShowConnectingPage: false,
        });
        const { isConnectingSuccess } = this.state;

        try {
            if (isConnectingSuccess) {
                await endConversation();
            }
        } finally {
            const { onChatWidgetClose } = this.props;

            if (onChatWidgetClose) {
                onChatWidgetClose();
            }
        }
    };

    handleModalSecondaryBtnClick = () => {
        const { isConnectingSuccess } = this.state;
        const newState = {
            shouldShowConfirmModal: false,
            shouldShowChatWindowPage: false,
            shouldShowConnectingPage: false,
        };

        if (isConnectingSuccess) {
            newState.shouldShowChatWindowPage = true;
        } else {
            newState.shouldShowConnectingPage = true;
        }
        this.setState(newState);
    };

    handleCloseBtnClick = () => {
        this.setState({
            shouldShowConfirmModal: true,
        });
    };

    initConversation = async () => {
        try {
            const conversation = await getConversation();
            const participantsCount = await conversation.getParticipantsCount();

            if (participantsCount > 1) {
                this.handleJoined();
            } else {
                await this.sendInitMessage();
                conversation.on(
                    Conversation.participantJoined,
                    this.handleJoined,
                );
            }
        } catch {
            this.handleError();
        }
    };

    sendInitMessage = async () => {
        const guid = uuidv4();

        const messageObj = {
            body: '',
            index: guid,
            isFailed: false,
        };
        await sendMessage(messageObj);
    };

    detachConversationEvents = async () => {
        try {
            const conversation = await getConversation();
            conversation.off(Conversation.participantJoined, this.handleJoined);
        } catch {
            this.handleError();
        }
    };

    modalWapperRenderToElement = () => {
        return this.webChatChatWindowElement;
    };

    baseCls = 'web-chat-connecting-window';
}

export default ChatConnectingWindow;
