import { generateCSSVars } from '@Green-Dot-Corporation/eureka-lib-theme-utils';
import { get } from '@Green-Dot-Corporation/eureka-lib-fetch-utils';
import { getResourceUrl } from './urlHelper';
import { PROGRAM_CODE } from '../const/urlConst';

export default {};
const reCalculateFontSize = (designToken) => {
    const newDesginToken = { ...designToken };
    const bodyEle = document.getElementsByTagName('body')[0];
    const { fontSize } = getComputedStyle(bodyEle);

    if (fontSize && fontSize.includes('px')) {
        const fs = +fontSize.slice(0, fontSize.length - 2);
        const fontSizeKeys = Object.keys(designToken).filter((tokenName) =>
            tokenName.includes('font.size'),
        );

        fontSizeKeys.forEach((key) => {
            const tokenValue = designToken[key];

            if (tokenValue) {
                newDesginToken[key] = ((10 / fs) * tokenValue).toFixed(0);
            }
        });
    }

    return newDesginToken;
};

const getBrandDesignToken = async (brand) => {
    const resourceUrlPrefix = getResourceUrl();
    const upperCaseBrand = brand.toUpperCase();
    const lowerCaseBrand = brand.toLowerCase();
    const relativePath = `/resources/${lowerCaseBrand}/${PROGRAM_CODE[upperCaseBrand]}/branding/web.json`;

    const designTokenUrl = resourceUrlPrefix + relativePath;

    const desginToken = await get(designTokenUrl, {
        credentials: 'same-origin',
    });

    return reCalculateFontSize(desginToken);
};

export const importCSS = async (brand) => {
    const designToken = await getBrandDesignToken(brand);
    const cssVariable = generateCSSVars(designToken);

    const stylesheet = document.createElement('style');
    stylesheet.appendChild(document.createTextNode(cssVariable));
    document.head.appendChild(stylesheet);
};
