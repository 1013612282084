/* eslint-disable filenames/match-exported */

import React from 'react';
import ReactDOM from 'react-dom/client';

import ChatModule from './ChatModule';

const DEFAULT_ELEMENT_ID = 'gd-chat-root';

const renderChat = (parentElement, config) => {
    if (!parentElement) {
        throw new Error('Panrent element is null.');
    }
    let existElement = document.getElementById(DEFAULT_ELEMENT_ID);

    if (!existElement) {
        existElement = document.createElement('div');
        existElement.id = DEFAULT_ELEMENT_ID;
    }

    parentElement.appendChild(existElement);
    const root = ReactDOM.createRoot(existElement);

    const handleCloseWindow = () => {
        const { onChatWidgetClose } = config;

        if (onChatWidgetClose) {
            onChatWidgetClose();
        }
        root.unmount();
    };

    root.render(
        <React.StrictMode>
            <ChatModule
                {...config}
                containerElement={existElement}
                onChatWidgetClose={handleCloseWindow}
            />
        </React.StrictMode>,
    );
};

export default renderChat;
