import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class ChatTextBubbleRightTail extends PureComponent {
    static propTypes = {
        contextCls: PropTypes.string,
    };

    render() {
        const { contextCls } = this.props;

        return (
            <svg
                className={cx(this.baseCls, contextCls)}
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M-0.000148773 0H5.99985C5.99985 4.82743 5.65399 12.7323 11.1514 15C4.94531 15 0.969548 12.3333 -0.000148773 11V0Z"
                    fill="currentColor"
                />
            </svg>
        );
    }

    baseCls = 'web-chat-bubble-right-tail';
}

export default ChatTextBubbleRightTail;
