import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class Image extends PureComponent {
    static propTypes = {
        contextCls: PropTypes.string,
    };

    render() {
        const { contextCls } = this.props;

        return (
            <svg
                className={cx(this.baseCls, contextCls)}
                width="35"
                height="28"
                viewBox="0 0 35 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M29.1668 0.666626H5.8335C4.50741 0.666626 3.23564 1.19341 2.29796 2.13109C1.36028 3.06877 0.833496 4.34054 0.833496 5.66663V22.3333C0.833496 23.6594 1.36028 24.9311 2.29796 25.8688C3.23564 26.8065 4.50741 27.3333 5.8335 27.3333H29.1668C30.4929 27.3333 31.7647 26.8065 32.7024 25.8688C33.64 24.9311 34.1668 23.6594 34.1668 22.3333V5.66663C34.1668 4.34054 33.64 3.06877 32.7024 2.13109C31.7647 1.19341 30.4929 0.666626 29.1668 0.666626ZM5.8335 24C5.39147 24 4.96755 23.8244 4.65499 23.5118C4.34242 23.1992 4.16683 22.7753 4.16683 22.3333V18.3L9.66683 12.8166C9.97838 12.5112 10.3972 12.3402 10.8335 12.3402C11.2698 12.3402 11.6886 12.5112 12.0002 12.8166L23.1835 24H5.8335ZM30.8335 22.3333C30.8335 22.7753 30.6579 23.1992 30.3453 23.5118C30.0328 23.8244 29.6089 24 29.1668 24H27.8835L21.5335 17.6166L23.0002 16.15C23.3117 15.8446 23.7306 15.6735 24.1668 15.6735C24.6031 15.6735 25.0219 15.8446 25.3335 16.15L30.8335 21.6333V22.3333ZM30.8335 16.9333L27.7002 13.8166C26.7503 12.904 25.4841 12.3943 24.1668 12.3943C22.8496 12.3943 21.5834 12.904 20.6335 13.8166L19.1668 15.2833L14.3668 10.4833C13.4169 9.57068 12.1508 9.061 10.8335 9.061C9.51624 9.061 8.25007 9.57068 7.30016 10.4833L4.16683 13.6V5.66663C4.16683 5.2246 4.34242 4.80068 4.65499 4.48811C4.96755 4.17555 5.39147 3.99996 5.8335 3.99996H29.1668C29.6089 3.99996 30.0328 4.17555 30.3453 4.48811C30.6579 4.80068 30.8335 5.2246 30.8335 5.66663V16.9333Z"
                    fill="currentColor"
                />
            </svg>
        );
    }

    baseCls = 'web-chat-svg-image';
}

export default Image;
