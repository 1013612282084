import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Callout } from '@Green-Dot-Corporation/eureka-ui-callouts';
import { Button, VARIANTS } from '@Green-Dot-Corporation/eureka-ui-buttons';
import { getTranslation } from '@Green-Dot-Corporation/eureka-lib-i18n-utils';
import WindowContainer from '../window-container/WindowContainer';
import LeaveMessage from '../leave-message/LeaveMessage';
import './styles/hoop.scss';
import Img from '../img/Img';

const { SECONDARY } = VARIANTS;

class Hoop extends PureComponent {
    static propTypes = {
        contextCls: PropTypes.string,
        hoop: PropTypes.string,
        isLeaveAgentMessageEnabled: PropTypes.bool,
    };

    state = {
        shouldShowHoopWindow: true,
        shouldShowLeaveMessageWindow: false,
    };

    render() {
        const { shouldShowHoopWindow, shouldShowLeaveMessageWindow } =
            this.state;
        const { contextCls } = this.props;

        return (
            <div className={cx(this.baseCls, contextCls)}>
                {shouldShowHoopWindow && this.renderHoopWindow()}
                {shouldShowLeaveMessageWindow &&
                    this.renderLeaveMessageWindow()}
            </div>
        );
    }

    renderClockIcon = () => (
        <Img filename="clock.svg" alt={getTranslation('hoop.imgAlt.clock')} />
    );

    renderHoopWindow = () => {
        const { contextCls, hoop, isLeaveAgentMessageEnabled, ...rest } =
            this.props;

        const title = `${getTranslation('hoop.hoopMessage')}`.replace(
            '{hoop}',
            hoop,
        );

        const hoopMessage = isLeaveAgentMessageEnabled
            ? getTranslation('hoop.hoopMessage.leaveAgentMsgEnabled')
            : getTranslation('hoop.hoopMessage.leaveAgentMsgNotEnabled');

        const isShowLeaveMessageBtn = isLeaveAgentMessageEnabled;

        return (
            <WindowContainer
                {...rest}
                contextCls={`${this.baseCls}__hoop`}
                shouldShowCloseBtn
                onCloseBtnClick={this.handleCloseBtnClick}
                shouldCloseWindowContainer={!this.state.shouldShowHoopWindow}
            >
                <div className={`${this.baseCls}__hoop-content`}>
                    <Callout
                        renderIcon={this.renderClockIcon}
                        title={title}
                        msg={hoopMessage}
                    />

                    {isShowLeaveMessageBtn && this.renderLeaveMessageBtn()}
                </div>
            </WindowContainer>
        );
    };

    renderLeaveMessageBtn = () => {
        return (
            <Button
                contextCls={cx(`${this.baseCls}__leave-message-btn`)}
                text={getTranslation('hoop.leaveMsgBtn.text')}
                variant={SECONDARY}
                onClick={this.handleLeaveMessageBtnClick}
                aria-label={getTranslation('hoop.leaveMsgBtn.text')}
            />
        );
    };

    renderLeaveMessageWindow = () => {
        const { contextCls, ...rest } = this.props;

        return (
            <LeaveMessage
                {...rest}
                contextCls={`${this.baseCls}__leave-message`}
                onMessageSent={this.handleMessageSent}
            />
        );
    };

    handleLeaveMessageBtnClick = () => {
        this.setState({
            shouldShowHoopWindow: false,
            shouldShowLeaveMessageWindow: true,
        });
    };

    baseCls = 'web-chat-hoop';
}

export default Hoop;
