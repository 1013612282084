import {
    get as getFetch,
    post as postFetch,
} from '@Green-Dot-Corporation/eureka-lib-fetch-utils';
import GlobalSettings from './GlobalSettings';
import { addTokenOptions } from './httpHelper';
import { getPlatform, parameterizeUrl } from './urlHelper';
import { BRAND, PREFIX_URL_CONFIG } from '../const/urlConst';

export default {};

let accessToken;
let applicationId;
let prefixAPIUrl;
let env;

export const getAccessToken = () => accessToken;

export const setAccessToken = (value) => {
    accessToken = value;
};

export const getApplicationId = () => applicationId;

export const setApplicationId = (value) => {
    applicationId = value;
};

export const getEnv = () => env;

export const setEnv = (value) => {
    env = value;
};

export const getPrefixAPIUrl = () => prefixAPIUrl;

export const setPrefixAPIUrl = () => {
    let defaultUrl = PREFIX_URL_CONFIG[getPlatform()][getEnv()];

    if (GlobalSettings.Brand === BRAND.RUSHCARD) {
        defaultUrl =
            PREFIX_URL_CONFIG[getPlatform()][BRAND.RUSHCARD.toUpperCase()][
                getEnv()
            ];
    }
    prefixAPIUrl = defaultUrl;
};

export const get = async (url, options) => {
    const tokenAddedOptions = addTokenOptions(options);
    const resp = await getFetch(
        parameterizeUrl(`${prefixAPIUrl}${url}`),
        tokenAddedOptions,
    );

    return resp;
};

export const post = async (url, body, options) => {
    const tokenAddedOptions = addTokenOptions(options);
    tokenAddedOptions.body = body;
    const resp = await postFetch(
        parameterizeUrl(`${prefixAPIUrl}${url}`),
        tokenAddedOptions,
    );

    return resp;
};

export const cleanData = () => {
    accessToken = '';
    applicationId = '';
    prefixAPIUrl = '';
    env = '';
};
