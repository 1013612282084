import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class ChatTextBubbleLeftTail extends PureComponent {
    static propTypes = {
        contextCls: PropTypes.string,
    };

    render() {
        const { contextCls } = this.props;

        return (
            <svg
                className={cx(this.baseCls, contextCls)}
                width="11"
                height="15"
                viewBox="0 0 11 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11 0H5C5 4.86555 5.60812 12.6867 0 15C6.20606 15 10.0303 12.3333 11 11V0Z"
                    fill="currentColor"
                />
            </svg>
        );
    }

    baseCls = 'web-chat-bubble-left-tail';
}

export default ChatTextBubbleLeftTail;
