const BAAS_URL_CONFIG = {
    GET_CONFIG: '/api/communication/config',
    LEAVE_MESSAGE: '/api/communication/leaveagentmessage',
    REQUEST_USER_TOKEN: '/api/communication/user/token/request?component=CHAT',
    START_CONVERSATION: '/api/communication/conversation/start',
    END_CONVERSATION: '/api/communication/conversation/end',
};

const LEGACY_URL_CONFIG = {
    GET_CONFIG: '/communication/config',
    LEAVE_MESSAGE: '/communication/leaveagentmessage',
    REQUEST_USER_TOKEN: '/communication/user/token/request',
    START_CONVERSATION: '/{applicationId}/communication/conversation/start',
    END_CONVERSATION: '/communication/conversation/end',
};

const LEGACY_RUSH_CARD_URL_CONFIG = {
    GET_CONFIG: '/api/communication/config',
    LEAVE_MESSAGE: '/api/communication/leaveagentmessage',
    REQUEST_USER_TOKEN: '/api/communication/user/token/request',
    START_CONVERSATION: '/api/{applicationId}/communication/conversation/start',
    END_CONVERSATION: '/api/communication/conversation/end',
};

const PREFIX_URL_CONFIG = {
    LEGACY: {
        QA: 'https://secure.greendot.com/api',
        PIE: 'https://secure.greendot.com/api',
        PROD: 'https://secure.greendot.com/api',
        // rushcard requests the api address of the same domain
        RUSHCARD: {
            QA: '',
            PIE: '',
            PROD: '',
        },
    },
    BAAS: {
        DEV: 'https://qa-secure-gdrewardsdev.nextestate.com/BaaSGateway/v1',
        QA: 'https://qa-secure-gdrewardsdev.nextestate.com/BaaSGateway/v1',
        PIE: 'https://pie-secure-gdrewardsdev.nextestate.com/BaaSGateway/v1',
        PROD: 'https://secure.go2bank.com/api/BaaSGateway/v1', // this URL cannot be changed, all BaaS brands and pre-login marketing page are using this prod URL
    },
};

const PREFIX_URL_RESOURCES = {
    DEV: 'https://dev-cdn.go2bankonline.com',
    QA: 'https://qa-cdn.go2bankonline.com',
    PIE: 'https://pie-cdn.go2bankonline.com',
    PROD: 'https://prod-cdn.go2bankonline.com',
};

const PLATFORM = {
    LEGACY: 'LEGACY',
    BAAS: 'BAAS',
};

const BRAND = {
    GO2BANK: 'go2bank',
    WMMC: 'wmmc',
    GOBANK: 'gobank',
    WALMART: 'walmart',
    GREENDOT: 'greendot',
    INTUIT: 'intuit',
    RUSHCARD: 'rushcard',
};

const PROGRAM_CODE = {
    GO2BANK: 'gbr',
    WMMC: 'wmmc',
    GREENDOT: 'greendot',
    WALMART: 'walmart',
    INTUIT: 'intuit',
    GOBANK: 'gobank',
    RUSHCARD: 'rushcard',
};

const ENVIRONMENT = {
    DEV: 'DEV',
    QA: 'QA',
    PIE: 'PIE',
    PROD: 'PROD',
};

export {
    BAAS_URL_CONFIG,
    LEGACY_URL_CONFIG,
    PLATFORM,
    BRAND,
    PROGRAM_CODE,
    PREFIX_URL_CONFIG,
    PREFIX_URL_RESOURCES,
    ENVIRONMENT,
    LEGACY_RUSH_CARD_URL_CONFIG,
};
