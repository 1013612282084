import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tile } from '@Green-Dot-Corporation/eureka-ui-layouts';
import { EurekaMinus, EurekaX } from '@Green-Dot-Corporation/eureka-ui-icons';
import { Heading } from '@Green-Dot-Corporation/eureka-ui-typography';
import { DialogAction } from '@Green-Dot-Corporation/eureka-ui-buttons';
import { getTranslation } from '@Green-Dot-Corporation/eureka-lib-i18n-utils';
import IconButtonChat from '../icon-button-chat/IconButtonChat';
// Styles
import './styles/window-container.scss';
import Img from '../img/Img';

class WindowContainer extends PureComponent {
    static defaultProps = {
        shouldShowMinimizeBtn: false,
        shouldShowCloseBtn: false,
        onChatWidgetClose: () => Promise.resolve(),
    };

    static propTypes = {
        contextCls: PropTypes.string,
        title: PropTypes.string,
        shouldShowMinimizeBtn: PropTypes.bool,
        shouldShowCloseBtn: PropTypes.bool,
        onCloseBtnClick: PropTypes.func,
        onMinimizeBtnClick: PropTypes.func,
        onChatWidgetClose: PropTypes.func,
    };

    state = {
        shouldShowMinimizedIconButton: false,
        shouldCloseWindow: false,
    };

    render() {
        const { shouldShowMinimizedIconButton, shouldCloseWindow } = this.state;
        const { contextCls } = this.props;

        return (
            <div className={cx(this.baseCls, contextCls)}>
                {shouldShowMinimizedIconButton &&
                    !shouldCloseWindow &&
                    this.renderIconButtonChat()}
                {!shouldShowMinimizedIconButton &&
                    !shouldCloseWindow &&
                    this.renderTile()}
            </div>
        );
    }

    renderTile = () => {
        const { contextCls, children } = this.props;

        return (
            <Tile
                renderHeading={this.renderHeading}
                contextCls={cx(this.baseTileCls, contextCls)}
            >
                {children}
            </Tile>
        );
    };

    renderHeading = () => {
        const { title, shouldShowMinimizeBtn, shouldShowCloseBtn } = this.props;

        return (
            <div className={`${this.baseTileCls}-header`}>
                <Heading contextCls={`${this.baseTileCls}-header-title`}>
                    {title}
                </Heading>
                {shouldShowMinimizeBtn && this.renderMinimizeBtn()}
                {shouldShowCloseBtn && this.renderCloseBtn()}
            </div>
        );
    };

    renderCloseBtn = () => {
        const ariaLabelText = getTranslation(
            'windowContainer.buttons.closeButtonAriaLabel',
        );

        return (
            <div
                className={cx(
                    this.baseTitleBtnContainerCls,
                    `${this.baseTitleBtnContainerCls}--close-btn-container`,
                )}
            >
                <DialogAction
                    contextCls={`${this.baseCls}__close-btn`}
                    renderIcon={this.renderCloseBtnIcon}
                    onClick={this.handleCloseBtnClick}
                    aria-label={ariaLabelText}
                />
            </div>
        );
    };

    renderMinimizeBtn = () => {
        const ariaLabelText = getTranslation(
            'windowContainer.buttons.minimizeButtonAriaLabel',
        );

        return (
            <div
                className={cx(
                    this.baseTitleBtnContainerCls,
                    `${this.baseTitleBtnContainerCls}--minimize-btn-container`,
                )}
            >
                <DialogAction
                    contextCls={`${this.baseCls}__minimize-btn`}
                    renderIcon={this.renderMinimizeBtnIcon}
                    onClick={this.handleMinimizeBtnClick}
                    // TODO: replace the aria-label title when localization available
                    aria-label={ariaLabelText}
                />
            </div>
        );
    };

    renderMinimizeBtnIcon = () => <EurekaMinus />;

    renderCloseBtnIcon = () => <EurekaX />;

    renderSvgChat = () => (
        <Img
            filename="chat.svg"
            alt={getTranslation('windowContainer.imgAlt.chat')}
        />
    );

    renderIconButtonChat = () => {
        return (
            <IconButtonChat
                contextCls={`${this.baseCls}__icon-button`}
                renderSvg={this.renderSvgChat}
                onClick={this.handleMinimizedIconBtnClick}
            />
        );
    };

    handleCloseBtnClick = () => {
        const { onCloseBtnClick, onChatWidgetClose } = this.props;

        if (onCloseBtnClick) {
            onCloseBtnClick();
        } else {
            this.setState({
                shouldCloseWindow: true,
            });

            if (onChatWidgetClose) {
                onChatWidgetClose();
            }
        }
    };

    handleMinimizeBtnClick = () => {
        const { onMinimizeBtnClick } = this.props;

        if (onMinimizeBtnClick) {
            onMinimizeBtnClick();
        } else {
            this.setState({
                shouldShowMinimizedIconButton: true,
            });
        }
    };

    handleMinimizedIconBtnClick = () => {
        this.setState({
            shouldShowMinimizedIconButton: false,
        });
    };

    baseCls = 'web-chat-window-container';

    baseTileCls = `${this.baseCls}__tile`;

    baseTitleBtnContainerCls = `${this.baseCls}__title-btn-container`;
}

export default WindowContainer;
