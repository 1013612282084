import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { LinkButton } from '@Green-Dot-Corporation/eureka-ui-buttons';
import { getTranslation } from '@Green-Dot-Corporation/eureka-lib-i18n-utils';
import ChatTextBubbleRightTail from '../svg/ChatTextBubbleRightTail';
import ChatTextBubbleLeftTail from '../svg/ChatTextBubbleLeftTail';
import ImageIcon from '../svg/Image';
import ImageSlash from '../svg/ImageSlash';
// Styles
import './styles/chat-image-bubble.scss';

class ChatImageBubble extends PureComponent {
    static propTypes = {
        contextCls: PropTypes.string,
        isFromOther: PropTypes.bool,
        media: PropTypes.object,
        isFailed: PropTypes.bool,
        index: PropTypes.string,
        onRetry: PropTypes.func,
        onRemove: PropTypes.func,
    };

    static defaultProps = {
        isFromOther: false,
    };

    state = {
        isLoading: true,
        isImageFetchFailedFromOther: false,
    };

    render() {
        const { contextCls } = this.props;
        const { isLoading } = this.state;

        return (
            <div className={cx(this.baseCls, contextCls)}>
                {isLoading && this.renderLoading()}
                {!isLoading && this.renderImageThumbnail()}
            </div>
        );
    }

    componentDidMount() {
        this.loadMedia();
    }

    renderLoading = () => {
        const { isImageFetchFailedFromOther } = this.state;
        const { contextCls, isFromOther, media, index, isFailed } = this.props;
        const id = media.sid;
        const modCls = this.getModCls();

        return (
            <div id={id} className={cx(this.baseCls, modCls, contextCls)}>
                <div className={`${this.baseCls}__loading-actions-container`}>
                    <div className={`${this.baseCls}__loading-container`}>
                        <div className={`${this.baseCls}__loading-bubble`}>
                            {isFromOther && isImageFetchFailedFromOther
                                ? this.renderBrokenImageIcon()
                                : this.renderDefaultImageIcon()}
                            {isFromOther &&
                                !isImageFetchFailedFromOther &&
                                this.renderLoadingImageFromOtherStatus()}
                            {!isFromOther && this.renderTail()}
                        </div>
                        <div className={`${this.baseCls}__uploading-status`}>
                            {index &&
                                !isFailed &&
                                getTranslation(
                                    'chatImageBubble.uploadingStatus.text',
                                )}
                        </div>
                    </div>
                    {isFailed && this.renderActions()}
                    {isFromOther &&
                        isImageFetchFailedFromOther &&
                        this.renderRetryFetchImageAction()}
                </div>
            </div>
        );
    };

    renderActions = () => {
        const { index } = this.props;

        return (
            <div className={`${this.baseCls}__actions-container`}>
                <span className={`${this.baseCls}__actions-text`}>
                    {getTranslation('chatImageBubble.actionsContainerText')}
                </span>
                <LinkButton
                    contextCls={`${this.baseCls}__actions-link-retry`}
                    text={getTranslation(
                        'chatImageBubble.actionsContainer.retryLinkButtonText',
                    )}
                    data={{ index }}
                    onClick={this.handleRetryClick}
                    aria-label={getTranslation(
                        'chatImageBubble.actionsContainer.retryLinkButtonAriaLabel',
                    )}
                />
                <span className={`${this.baseCls}__actions-divider`}>
                    {getTranslation(
                        'chatImageBubble.actionsContainer.dividerText',
                    )}
                </span>
                <LinkButton
                    contextCls={`${this.baseCls}__actions-link-remove`}
                    text={getTranslation(
                        'chatImageBubble.actionsContainer.removeLinkButtonText',
                    )}
                    data={{ index }}
                    onClick={this.handleRemoveClick}
                    aria-label={getTranslation(
                        'chatImageBubble.actionsContainer.removeLinkButtonAriaLabel',
                    )}
                />
            </div>
        );
    };

    renderRetryFetchImageAction = () => {
        return (
            <div className={`${this.baseCls}__actions-container`}>
                <span className={`${this.baseCls}__actions-text`}>
                    {getTranslation(
                        'chatImageBubble.actionsFromOtherContainerText',
                    )}
                </span>
                <LinkButton
                    contextCls={`${this.baseCls}__actions-link-retry`}
                    text={getTranslation(
                        'chatImageBubble.actionsContainer.retryLinkButtonText',
                    )}
                    onClick={this.handleRetryLoadingImageFromOtherClick}
                    aria-label={getTranslation(
                        'chatImageBubble.actionsContainer.retryLinkButtonAriaLabel',
                    )}
                />
            </div>
        );
    };

    renderImageThumbnail = () => {
        const { mediaUrl } = this.state;
        const { contextCls, media } = this.props;
        const id = media.sid;
        const fileName = media.filename;

        const modCls = this.getModCls();

        return (
            <div id={id} className={cx(this.baseCls, modCls, contextCls)}>
                <a
                    className={`${this.baseCls}__bubble-thumbnail-container`}
                    href={mediaUrl}
                    target="_blank"
                    aria-label={getTranslation('chatImageBubble.img')}
                >
                    <div className={`${this.baseCls}__thumbnail-container`}>
                        <img
                            className={`${this.baseCls}__thumbnail`}
                            width="100%"
                            height="100%"
                            src={mediaUrl}
                            alt={`${fileName}`}
                        />
                    </div>

                    <div className={`${this.baseCls}__modal-scrim`}>
                        <div className={`${this.baseCls}__modal-scrim-content`}>
                            {this.renderDefaultImageIcon()}
                            <div
                                className={`${this.baseCls}__modal-scrim-hint`}
                            >
                                {getTranslation('chatImageBubble.img')}
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        );
    };

    renderDefaultImageIcon = () => <ImageIcon />;

    renderBrokenImageIcon = () => <ImageSlash />;

    renderLoadingImageFromOtherStatus = () => {
        return (
            <p className={`${this.baseCls}__loading-image-from-other-status`}>
                {getTranslation(
                    'chatImageBubble.imageLoadingFromOtherStatus.text',
                )}
            </p>
        );
    };

    renderTail = () => {
        const { isFromOther } = this.props;
        const BubbleTail = isFromOther
            ? ChatTextBubbleLeftTail
            : ChatTextBubbleRightTail;

        return <BubbleTail contextCls={`${this.baseCls}__tail`} />;
    };

    handleRetryClick = (e) => {
        const { onRetry } = this.props;
        onRetry && onRetry(e);
    };

    handleRemoveClick = (e) => {
        const { onRemove } = this.props;
        onRemove && onRemove(e);
    };

    handleRetryLoadingImageFromOtherClick = () => {
        this.setState({ isLoading: true, isImageFetchFailedFromOther: false });
        this.loadMedia();
    };

    // Get Modifier Classes
    getModCls = () => {
        const { isFromOther } = this.props;

        return {
            [`${this.baseCls}--from-me`]: !isFromOther,
            [`${this.baseCls}--from-other`]: isFromOther,
        };
    };

    loadMedia = async () => {
        const { media } = this.props;
        const newState = {};

        try {
            const url = await media.getContentTemporaryUrl();

            if (url) {
                newState.mediaUrl = url;
                newState.isLoading = false;
                newState.isImageFetchFailedFromOther = false;
            } else {
                newState.isImageFetchFailedFromOther = true;
            }
        } catch (err) {
            newState.isImageFetchFailedFromOther = true;
        } finally {
            this.setState(newState);
        }
    };

    // Base class which all component class names should be
    // derived from
    baseCls = 'web-chat-image-bubble';
}
export default ChatImageBubble;
