import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getTranslation } from '@Green-Dot-Corporation/eureka-lib-i18n-utils';
import { Button, VARIANTS } from '@Green-Dot-Corporation/eureka-ui-buttons';
import { Conversation } from '@twilio/conversations';
import { getConversation } from '../../services/twilioService';
// Styles
import './styles/icon-button-chat.scss';

const { SECONDARY } = VARIANTS;
class IconButtonChat extends PureComponent {
    static defaultProps = {
        onClick: () => Promise.resolve(),
        renderSvg: () => {},
    };

    static propTypes = {
        contextCls: PropTypes.string,
        shouldShowUnreadCount: PropTypes.bool,
        onClick: PropTypes.func,
        renderSvg: PropTypes.func,
    };

    state = { unreadMessageCount: 0 };

    render() {
        const { contextCls } = this.props;

        return (
            <div className={cx(`${this.baseCls}`, contextCls)}>
                <div className={`${this.baseCls}__container`}>
                    {this.renderIconButton()}
                    {this.renderCounter()}
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.attachConversationEvent();
    }

    componentWillUnmount() {
        this.detachConversationEvent();
    }

    renderIconButton = () => {
        const btnText = getTranslation('iconButtonChat.buttonText');
        const btnAriaLabel = getTranslation('iconButtonChat.buttonAriaLabel');

        return (
            <Button
                contextCls={cx(`${this.baseCls}__icon-btn-chat`)}
                renderIcon={this.renderSvgIcon}
                onClick={this.handleClick}
                variant={SECONDARY}
                text={btnText}
                aria-label={btnAriaLabel}
            />
        );
    };

    renderCounter = () => {
        const modCls = this.getModCls();

        return (
            <div
                className={cx(`${this.baseCls}__count`, modCls)}
                data-count={this.state.unreadMessageCount}
            />
        );
    };

    renderSvgIcon = () => {
        const { renderSvg } = this.props;

        return renderSvg();
    };

    handleClick = () => {
        const { onClick } = this.props;
        onClick();
    };

    handleMessageAdd = () => {
        const { unreadMessageCount } = this.state;
        this.setState({
            unreadMessageCount: unreadMessageCount + 1,
        });
    };

    // Get Modifier Classes
    getModCls = () => {
        const { shouldShowUnreadCount } = this.props;

        return {
            [`${this.baseCls}__count-number`]: shouldShowUnreadCount,
        };
    };

    attachConversationEvent = async () => {
        const conversation = await getConversation();
        conversation.on(Conversation.messageAdded, this.handleMessageAdd);
    };

    detachConversationEvent = async () => {
        const conversation = await getConversation();
        conversation.off(Conversation.messageAdded, this.handleMessageAdd);
    };

    // Base class which all component class names should be
    // derived from
    baseCls = 'icon-button-chat';
}

export default IconButtonChat;
