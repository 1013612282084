import { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import GlobalSettings from '../../utils/GlobalSettings';
import { getResourceUrl } from '../../utils/urlHelper';
import { PROGRAM_CODE } from '../../const/urlConst';
import './styles/img.scss';

export default class Img extends Component {
    static propTypes = {
        filename: PropTypes.string.isRequired,
        alt: PropTypes.string,
        contextCls: PropTypes.string,
    };

    state = {
        isSrcError: false,
    };

    render() {
        const { filename, alt, contextCls, ...rest } = this.props;
        const { isSrcError } = this.state;

        return (
            <img
                {...rest}
                src={
                    isSrcError
                        ? this.getFallbackImageUrl()
                        : this.getFullImageUrl()
                }
                alt={alt ?? ''}
                className={cx(this.baseCls, contextCls)}
                onError={this.handleImageError}
            />
        );
    }

    handleImageError = () => {
        this.setState({
            isSrcError: true,
        });
    };

    getRelativeImageUrl = (brand, filename) => {
        const lowerCaseBrand = brand.toLowerCase();
        const upperCaseBrand = brand.toUpperCase();
        const relativePath = `/resources/${lowerCaseBrand}/${PROGRAM_CODE[upperCaseBrand]}/media/images/WebChat/${filename}`;

        return relativePath;
    };

    getFullImageUrl = () => {
        const { filename } = this.props;
        const brand = GlobalSettings.Brand;

        const fullImageUrl =
            this.determinedImagePrefixUrl() +
            this.getRelativeImageUrl(brand, filename);

        return fullImageUrl;
    };

    getFallbackImageUrl = () => {
        const { filename } = this.props;
        const fallbackBrand = 'go2bank';

        const fallbackImageUrl =
            this.determinedImagePrefixUrl() +
            this.getRelativeImageUrl(fallbackBrand, filename);

        return fallbackImageUrl;
    };

    determinedImagePrefixUrl = () => {
        return getResourceUrl();
    };

    baseCls = 'webchat-img';
}
